import { HashRouter as Router, Routes, Route , useLocation} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import CcpaPage from "./component/CCPA/ccpapage";
import Navbar from "./layout/Navbar";
import Home from "./Home";
import WhyBfag from "./component/About/WhyBfg/WhyBfg";
import VisionMission from "./component/About/OurVissionMission/VisionMission";
import HowwedoIt from "./component/About/HowweDoit/HowwedoIt";
import TestimonialsAndClients from "./component/About/TestimonialsAndClients/TestimonialsAndClients";
import ProductPage from "./component/Service/Product/ProductPage";
import CaseStudies from "./component/Service/CaseStudy/Casestudy";
import OurTeam from "./component/Service/OurTeam/Ourteam";
import NewTeam from "./component/About/MainTeam/OurTeam";
import Analyticsinbox from "./component/3Products/Analyticsinbox/Analyticsinbox";
import Expenseinbox from "./component/3Products/Expenseinbox/Expenseinbox";
import Complianceinbox from "./component/3Products/Complianceinbox/Complianceinbox";
import Services from "./component/AppServices/Services/Services";
import Newsletters from "./component/Infocenter/Newsletters/Newsletters";
import Blogs from "./component/Infocenter/Blogs/Blogs";
import Casestudies from "./component/Infocenter/CaseStudies/Casestudies";
import Itcompanycasestudy from "./component/Infocenter/CaseStudies/Itcompanycasestudy";
import Lawfirmcasestudy from "./component/Infocenter/CaseStudies/Lawfirmcasestudy";
import CPACaseStudy from "./component/Infocenter/CaseStudies/CPAcasestudy";
import VCfirmcasestudy from "./component/Infocenter/CaseStudies/VCfirmcasestudy";
import MediaHits from "./component/Infocenter/CaseStudies/Mediahit";
import Careers from "./component/Infocenter/CaseStudies/Careers";
import AccountingTransactionProcessing from "./component/Service/Accountingandtransitionprocessing/Accountingandtransitionprocessing";
import TrustCenter from "./trustcenter";
import PrivacyPolicy from "./component/Privacypolicy/Privacypolicy";
import Contactus from "./component/Contactus/Contactus";
import Faqoncpasolution from "./component/Faq/Faq-on-cpa/Faqoncpasolution";
import Faqindiaentitysetup from "./component/Faq/Faqindiaentitysetup/Faqindiaentitysetup";
import Faqodi from "./component/Faq/Faq-ODI/Faqodi";
import Faqfdi from "./component/Faq/Faq-Fdi/Faqfdi";
import Cpabackoffice from "./component/Cpabackoffice/Cpabackoffice";
import AuditSupport from "./component/Cpabackoffice/Auditsupport/Auditsupport";
import TaxPreparationComponent from "./component/Cpabackoffice/Taxreturnpreparation/Taxreturnpreparation";
import Cpaaccounting from "./component/Cpabackoffice/Accounting/Cpaaccounting";
import Sameerpaddalwar from "./component/About/MainTeam/Sameerpaddalwar/Sameerpaddalwar";
import Prafullachhajed from "./component/About/MainTeam/Prafullachhajed/Prafullachhajed";
import Anoopmehta from "./component/About/MainTeam/Anoopmehta/Anoopmehta";
import Sanjaykapadia from "./component/About/MainTeam/Sanjaykapadia/Sanjaykapadia";
import Ketan from "./component/About/MainTeam/Ketanchokshi/Ketan";
import Ganeshpadakannya from "./component/About/MainTeam/Ganeshpadakannya/Ganeshpadakannya";
import Amitmulgund from "./component/About/MainTeam/Amitmulgund/Amitmulgund";
import Kartikmehta from "./component/About/MainTeam/Kartikmehta/Kartikmehta";
import Ammartumpalliwar from "./component/About/MainTeam/Ammartumpalliwar/Ammartumpalliwar";
import Isopage from "./component/Isopage/Isopage";
import Endpointsecuritypage from "./component/Endpointsecuritypage/Endpointsecuritypage";
import Taxregulatorycompliances from "./component/AppServices/Taxregulatorycompliances/Taxregulatorycompliances";
import Payrolladvisory from "./component/AppServices/Payrolladvisory/Payrolladvisory";
import Consultingandadvisoryservices from "./component/AppServices/Consultingandadvisoryservices/Consultingandadvisoryservices";
import Startupsolutionservices from "./component/AppServices/Startupsolutionservices/Startupsolutionservices";
import Accountspayable from "./component/AppServices/Accountingandtransitionprocessing.js/Accountspayable/Accountspayable";
import Accountsreceivable from "./component/AppServices/Accountingandtransitionprocessing.js/Accountsreceivable/Accountsreceivable";
import Fixedassetsaccounting from "./component/AppServices/Accountingandtransitionprocessing.js/Fixedassetsaccounting/Fixedassetsaccounting";
import Financialreporting from "./component/AppServices/Accountingandtransitionprocessing.js/Financialreporting/Financialreporting";
import Budgetingandvarianceanalysis from "./component/AppServices/Accountingandtransitionprocessing.js/Budgetingandvarianceanalysis/Budgetingandvarianceanalysis";
import Reconciliationservices from "./component/AppServices/Reconciliationservices/Reconciliationservices";
import Consolidationreportinggaapconversion from "./component/AppServices/Consolidationreportinggaapconversion/Consolidationreportinggaapconversion";
import Incometax from "./component/AppServices/Taxregulatorycompliances/Incometax/Incometax";
import Goodsandservicetax from "./component/AppServices/Taxregulatorycompliances/Goodsandservicetax/Goodsandservicetax";
import Corporatesecretarialcompliance from "./component/AppServices/Taxregulatorycompliances/Corporatesecretarialcompliance/Corporatesecretarialcompliance";
import Femacompliance from "./component/AppServices/Taxregulatorycompliances/Femacompliance/Femacompliance";
import Payrollprocessing from "./component/AppServices/Payrolladvisory/Payrollprocessing/Payrollprocessing";
import Businesssetup from "./component/AppServices/Consultingandadvisoryservices/Businesssetup/Businesssetup";
import Globalcorporatestructuring from "./component/AppServices/Consultingandadvisoryservices/Globalcorporatestructuring/Globalcorporatestructuring";
import Businessplanpreparation from "./component/AppServices/Consultingandadvisoryservices/Businessplanpreparation/Businessplanpreparation";
import Businessvaluationservice from "./component/AppServices/Consultingandadvisoryservices/Businessvaluationservice/Businessvaluationservice";
import Mergersandacquisitions from "./component/AppServices/Consultingandadvisoryservices/Mergersandacquisitions/Mergersandacquisitions";
import Startupsolution from "./component/AppServices/Startupsolutionservices/Startupsolution/Startupsolution";
import Fundraising from "./component/AppServices/Startupsolutionservices/Fundraising/Fundraising";
import dsarpage from "./component/Dsarpage/Dsarpage";
import Dsarpage from "./component/Dsarpage/Dsarpage";
import ExecutionMethodology from "./component/About/HowweDoit/ExecutionMethodology/ExecutionMethodology";
import Datasecurityandconfidentiality from "./component/About/HowweDoit/Datasecurityandconfidentiality/Datasecurityandconfidentiality";
import Engagementprocess from "./component/About/HowweDoit/Engagementprocess/Engagementprocess";
import Flipcard from "./FlipCard/FlipCard";
import FirstBlog from "./component/Blogs/FirstBlog";
import SecondBlog from "./component/Blogs/SecondBlog";
import CookiesConsent from "./layout/cookiesConsent"; 
import CookiesGif from "./layout/CookiesGif";
import ThirdBlog from "./component/Blogs/ThirdBlog";
import ProductComponent from "./component/ProductComponent/ProductComponent";
import BlogTwo from "./component/BlogTwo";
import FourthBlog from "./component/Blogs/FourthBlog";
import FivethBlog from "./component/Blogs/FivethBlog";
import BlogOne from "./component/blogOne";
import Faqusaentitysetup from "./component/Faq/Faqusaentitysetup/Faqusaentitysetup";
import Smallbusinessaccounting from "./component/AppServices/Accountingandtransitionprocessing.js/Smallbusinessaccounting/Smallbusinessaccounting";
import Individualtaxreturn from "./component/AppServices/Taxregulatorycompliances/Individualtaxreturn/Individualtaxreturn";
import Corporatetaxreturn from "./component/AppServices/Taxregulatorycompliances/Corporatetaxreturn/Corporatetaxreturn";
import Salesandusatax from "./component/AppServices/Taxregulatorycompliances/Salesandusatax/Salesandusatax";
import Payrollandprocessing from "./component/Payrollandprocessing/Payrollandprocessing";
import ScrollToTop from "./component/ScrollToTop";
import Faquaeentitysetup from "./component/Faq/Faquaeentitysetup/Faquaeentitysetup";
import VATAdvisory from "./component/AppServices/Taxregulatorycompliances/VAT Advisory UAE/VATAdvisory";
import CorporateTaxAdvisory from "./component/AppServices/Taxregulatorycompliances/Corporate Tax Advisory/CorporateTaxAdvisory";
import AuditandAssurance from "./component/AppServices/AuditandAssurance/AuditandAssurance";
import InternalAudit from "./component/AppServices/AuditandAssurance/InternalAudit";
// import Payrollandprocessing from "./component/AppServices/Payrollandprocessing";
import Businesssetupuae from './component/AppServices/Consultingandadvisoryservices/Businesssetupuae/Businesssetupuae'
import StrategicCFOServices from "./component/AppServices/Consultingandadvisoryservices/StrategicCFOServices/StrategicCFOServices";
import Visionmissiongoal from "./component/OurVissionMission/Visionmissiongoal";
import IRSagaindelayseffectivedate from "./component/Blogs/IRSagaindelayseffectivedate";
import Supremecourtdefersfundamental from "./component/Blogs/Supremecourtdefersfundamental";
import Increasingincidenceoffinancial from "./component/Blogs/Increasingincidenceoffinancial";
import Auditedfinancialreport from "./component/Blogs/Auditedfinancialreport";
import Annualinflationadjustments from "./component/Blogs/Annualinflationadjustments";
import RecentBlogs from "./component/RecentBlogs/RecentBlogs";
import Fromseedtoscale from "./component/Blogs/Fromseedtoscale";
import Cryptocurrencytxesexplained from "./component/Blogs/Cryptocurrencytxesexplained";
import Virtualcpaservices from "./component/Blogs/Virtualcpaservices";
import Niteshvaswani from "./component/About/MainTeam/Niteshvaswani/Niteshvaswani";
import AnkushBirewar from "./component/About/MainTeam/Ankushbirewar/Ankushbirewar";
import Vamanld from "./component/About/MainTeam/VamanLd/vamanld";

// ScrollToTopWrapper for conditional rendering
function ScrollToTopWrapper() {
  const location = useLocation();
  
  // Routes where ScrollToTop should be excluded
  const excludedRoutes = ["/accountspayable", "/accountreceivable", "/fixed-assets-accounting","/financial-reporting","/budgeting-and-variance-analysis","/reconciliation-service","/consolidation-reporting-gaap-conversion",
"/Small-business-accounting","/Individual-tax-return","/Corporate-tax-return","/Sales-and-usa-tax","/payroll-and-processing","/payroll-processing","/payrolladvisory","/income-tax","/goods-and-service-tax","/corporate-secretarial-compliance",
"/Fema-compliance","/business-setup","/global-corporate-structuring","/business-plan-preparation","/business-valuation-service","/merge-and acquisitions","/startup-solution","/fund-raising","/strategic-cfo-services","/statutory-audit","/internal-audit",
"/vat-advisory","/Corporate-tax-return","/corporate-tax-advisory","/Bussiness-setup(Uae)", "/accounting-and-transaction-processing"
  ];
  
  return !excludedRoutes.includes(location.pathname) ? <ScrollToTop /> : null;
}

function App() {
  return (
    <Router>
      {/* <ScrollToTop/> */}
      <ScrollToTopWrapper />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ccpapage" element={<CcpaPage />} />
        <Route path="/RecentBlogs" element={<RecentBlogs/>} />
        <Route path="/whybfg" element={<WhyBfag />} />
        <Route path="/visionmission" element={<VisionMission />} />
        <Route path="/howwedoit" element={<HowwedoIt />} />
        <Route path="/testimonialclient" element={<TestimonialsAndClients />} />
        <Route path="/productnew" element={<ProductPage />} />
        <Route path="/casestudy" element={<CaseStudies />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/productpage" element={<ProductPage />} />
        <Route path="/product" element={<Analyticsinbox />} />
        <Route path="/Expensebox" element={<Expenseinbox />} />
        <Route path="/Compliancebox" element={<Complianceinbox />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/about/Ourteam" element={<NewTeam />} />
        <Route path="/newsletters" element={<Newsletters />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/casestudies" element={<Casestudies />} />
        <Route path="/itcompanycasestudy" element={<Itcompanycasestudy />} />
        <Route path="/lawfirmcasestudy" element={<Lawfirmcasestudy />} />
        <Route path="/CPAcasestudy" element={<CPACaseStudy />} />
        <Route path="/vcfirmcasestudy" element={<VCfirmcasestudy />} />
        <Route path="/mediahits" element={<MediaHits />} />
        <Route path="/careers" element={<Careers />} />
        <Route
          path="/accounting-and-transaction-processing"
          element={<AccountingTransactionProcessing />}
        />        
        <Route path='/Sales-and-usa-tax' element={<Salesandusatax/>}/>
        <Route path='/Corporate-tax-return' element={<Corporatetaxreturn/>}/>
        <Route path='/Individual-tax-return' element={<Individualtaxreturn/>}/>
        <Route path='/Small-business-accounting' element={<Smallbusinessaccounting/>}/>
        <Route path='/our-publication' element={<BlogOne/>}/>
        <Route path="/trustcenter" element={<TrustCenter />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/faqoncpasolution" element={<Faqoncpasolution />} />
        <Route path="/faqindiaentitysetup" element={<Faqindiaentitysetup />} />
        <Route path="/faqodi" element={<Faqodi />} />
        <Route path="/faqfdi" element={<Faqfdi />} />
        <Route path="/cpabackoffice" element={<Cpabackoffice />} />
        <Route path="/auditsupport" element={<AuditSupport />} />
        <Route
          path="/taxreturnpreparation"
          element={<TaxPreparationComponent />}
        />
        <Route path='/payroll-and-processing' element={<Payrollandprocessing/>}/>
         <Route path="/accounting" element={<Cpaaccounting />} />
        <Route path="/sameerpaddalwar" element={<Sameerpaddalwar />} />
        <Route path="/prafullachajed" element={<Prafullachhajed />} />
        <Route path="/Anoopmehta" element={<Anoopmehta />} />
        <Route path="/sanjaykapadia" element={<Sanjaykapadia />} />
        <Route path="/ketanchokshi" element={<Ketan />} />
        <Route path="/ganeshpadakannya" element={<Ganeshpadakannya />} />
        <Route path="/amitmulgund" element={<Amitmulgund />} />
        <Route path="/kartikmehta" element={<Kartikmehta />} />
        <Route path='/nitesh-vaswani' element={<Niteshvaswani/>}/>
        <Route path='/ankush-birewar' element={<AnkushBirewar/>}/>
        <Route path='/vaman-sriraman' element={<Vamanld/>}/>
        <Route path="/ammartumpalliwar" element={<Ammartumpalliwar />} />
        <Route path="/isopage" element={<Isopage />} />
        <Route
          path="/endpointsecuritypage"
          element={<Endpointsecuritypage />}
        />
        <Route
          path="/taxregulatorycompliances"
          element={<Taxregulatorycompliances />}
        />
        <Route path="/payrolladvisory" element={<Payrolladvisory />} />
        <Route
          path="/consultingandadvisoryservices"
          element={<Consultingandadvisoryservices />}
        />
        <Route
          path="/startupsolutionservices"
          element={<Startupsolutionservices />}
        />
        <Route path="/accountspayable" element={<Accountspayable />} />
        <Route path="/accountreceivable" element={<Accountsreceivable />} />
        <Route
          path="/fixed-assets-accounting"
          element={<Fixedassetsaccounting />}
        />
        <Route path='/faq-usa-entity-setup' element={<Faqusaentitysetup/>}/>
        <Route path="/financial-reporting" element={<Financialreporting />} />
        <Route
          path="/budgeting-and-variance-analysis"
          element={<Budgetingandvarianceanalysis />}
        />
        <Route
          path="/reconciliation-service"
          element={<Reconciliationservices />}
        />
        <Route
          path="/consolidation-reporting-gaap-conversion"
          element={<Consolidationreportinggaapconversion />}
        />
        <Route path="/income-tax" element={<Incometax />} />
        <Route path="/goods-and-service-tax" element={<Goodsandservicetax />} />
        <Route
          path="/corporate-secretarial-compliance"
          element={<Corporatesecretarialcompliance />}
        />
        <Route path="/Fema-compliance" element={<Femacompliance />} />
        <Route path="/payroll-processing" element={<Payrollprocessing />} />
        <Route path="/business-setup" element={<Businesssetup />} />
        <Route
          path="/global-corporate-structuring"
          element={<Globalcorporatestructuring />}
        />
        <Route
          path="/business-plan-preparation"
          element={<Businessplanpreparation />}
        />
        <Route
          path="/business-valuation-service"
          element={<Businessvaluationservice />}
        />
        <Route
          path="/merge-and acquisitions"
          element={<Mergersandacquisitions />}
        />
        <Route path="/startup-solution" element={<Startupsolution />} />
        <Route path="/fund-raising" element={<Fundraising />} />
        <Route path="/dsarpage" element={<Dsarpage />} />
        <Route
          path="/execution-methodology"
          element={<ExecutionMethodology />}
        />
        <Route
          path="/data-security"
          element={<Datasecurityandconfidentiality />}
        />
        <Route path="/engagement-process" element={<Engagementprocess />} />
        <Route path="/flipcard" element={<Flipcard />} />
        <Route path="/the-future-of-accouting" element={<FirstBlog />} />
        <Route path="/sustainable-finance" element={<SecondBlog />} />
        <Route path="/navigating-tax-changes" element={<ThirdBlog/>} />
        <Route path='/financial-services' element={<FourthBlog/>}/>
        <Route path='/navigating-crypto' element={<FivethBlog/>}/>
        <Route path='/products-component' element={<ProductComponent/>}/>
        <Route path='/component-blog' element={<BlogTwo/>}/>
        <Route path='/faq-uae-entity-setup' element={<Faquaeentitysetup/>}/>
        <Route path='/vat-advisory' element={<VATAdvisory/>}/>
        <Route path='/corporate-tax-advisory' element={<CorporateTaxAdvisory/>} />
        <Route path='/statutory-audit' element={<AuditandAssurance/>}/>
        <Route path='/internal-audit' element={<InternalAudit/>}/>
        <Route path='/Bussiness-setup(Uae)' element={<Businesssetupuae/>}/>
        <Route path='/strategic-cfo-services' element={<StrategicCFOServices/>}/>
        <Route path='/visionmissiongoal' element={<Visionmissiongoal/>}/>
        <Route path='/IRS-again-delays-effective-date' element={<IRSagaindelayseffectivedate/>}/>
        <Route path='/Supreme-court-defers-fundamental' element={<Supremecourtdefersfundamental/>}/>
        <Route path='/increasing-incidence-of-financial' element={<Increasingincidenceoffinancial/>}/>
        <Route path='/audited-financial-report' element={<Auditedfinancialreport/>}/>
        <Route path='/annual-inflation-adjustments' element={<Annualinflationadjustments/>}/>
        <Route path='/from-seed-to-scale' element={<Fromseedtoscale/>}/>
        <Route path="/cryptocurreny-taxes-explained" element={<Cryptocurrencytxesexplained/>} />
        <Route path='/virtual-cpa-services' element={<Virtualcpaservices/>}/>
      </Routes>
      <Footer />
      <CookiesConsent/>
      {/* <CookiesGif/> */}
      {/* <Bottomlayout/> */}
      {/* <Newfooter/> */}
 
    </Router>
  );
}

export default App;
