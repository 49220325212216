import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import blog11 from "../assets/images/blogs/blog11.jfif";
import blogoverlay from "../assets/images/blogs/blogoverlay.png";
import blogoverlay1 from "../assets/images/blogs/blogoverlay1.png";
import blogoverlay2 from "../assets/images/blogs/blogoverlay2.png";
import blogoverlay4 from "../assets/images/blogs/blogoverlay4.png";
import { Link } from "react-router-dom";
import latestblog1 from "../assets/images/blogs/latestblogimg2.jpeg";
import blogee2 from "../assets/images/blogs/blogee2.jpg";
import blogee3 from "../assets/images/blogs/3.jpg";
import blog6 from "../assets/images/blogs/6.png";
import blog7 from "../assets/images/blogs/7.png";
import blog8 from "../assets/images/blogs/8.png";
import blog9 from "../assets/images/blogs/9.png";
import blog10 from "../assets/images/blogs/10.png";
import scale from "../assets/images/blogs/scale.png";
import virtual1 from "../assets/images/blogs/virtual1.png";
import tax7 from "../assets/images/blogs/tax7.png";
import { Helmet } from "react-helmet";

const BlogTwo = () => {
  const [hovered, setHovered] = useState(null);
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Helmet>
        <title>Boston Financial Advisory Group - Insights and Expertise</title>
        <meta
          name="description"
          content="Discover expert insights on finance, accounting, law, and compliance. Our blog offers valuable knowledge on taxes, regulations, investments, and business growth to guide your financial decisions."
        />
        <meta
          name="keywords"
          content="- Tax financial advisor, Financial accounting services, CPA services, Boston financial advisors."
        />
      </Helmet>

      <div className="mb-50 mt-20" style={styles.cardContainer}>
        <div className="pb-2 mb-5">
          <h3
            className="text-center"
            style={{
              color: "#072f5f",
              display: "inline",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            Insights from Boston Financial
          </h3>
        </div>

        <div style={styles.row}>
          {/* card1 */}
          <Link
            to="/virtual-cpa-services"
            style={styles.card}
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImage,
                backgroundImage: `url(${virtual1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 1 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay1,
                  ...(hovered === 1 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Jan 14, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Virtual CPA Services: How to Maximize Your Business's
                <br /> Financial Health Remotely
              </div>
            </div>
          </Link>
          {/* card1 */}

          {/* card2 */}
          <Link
            to="/cryptocurreny-taxes-explained"
            style={styles.card}
            onMouseEnter={() => setHovered(2)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageSmall,
                backgroundImage: `url(${tax7})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 2 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay2,
                  ...(hovered === 2 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Jan 5, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle2}>
                Cryptocurrency Taxes Explained: <br /> A CPA’s Guide to
                Navigating the Digital Currency Landscape
              </div>
            </div>
          </Link>
          {/* card2 */}
        </div>

        <div style={styles.row}>
          {/* card3 */}
          <Link
            to="/from-seed-to-scale"
            style={styles.card}
            onMouseEnter={() => setHovered(3)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder,
                backgroundImage: `url(${scale})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 3 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay3,
                  ...(hovered === 3 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Dec 30, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                From Seed to Scale:
                <br /> How Pune's CAs are Fuelling the City's Startup Ecosystem
              </div>
            </div>
          </Link>

          {/* card3 */}

          {/* card4 */}
          <Link
            to="/annual-inflation-adjustments"
            style={styles.card}
            onMouseEnter={() => setHovered(4)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder4,
                backgroundImage: `url(${blog10})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 4 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay4,
                  ...(hovered === 4 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Dec 20, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Annual Inflation Adjustments for Tax Year 2025: What Boston{" "}
                <br /> Taxpayers Should Know
              </div>
            </div>
          </Link>
          {/* card4 */}

          {/* card5 */}
          <Link
            to="/audited-financial-report"
            style={styles.card}
            onMouseEnter={() => setHovered(5)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder5,
                backgroundImage: `url(${blog7})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 5 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay5,
                  ...(hovered === 5 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainerOpposite}>
                <div style={styles.cardDateOpposite}>Dec 15, 2024</div>
                <div style={styles.cardDateLineOpposite}></div>
              </div>
              <div style={styles.cardTitle}>
                Meaning of Audited Financial Reports to Businesses
              </div>
            </div>
          </Link>
          {/* card5 */}
        </div>

        <div style={styles.row}>
          {/* {blog6} */}
          <Link
            to="/increasing-incidence-of-financial"
            style={styles.card}
            onMouseEnter={() => setHovered(6)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImage6,
                backgroundImage: `url(${blogee3})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 6 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay6,
                  ...(hovered === 6 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Dec 11, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                The Increasing Incidence of Financial Statement Retractions:{" "}
                <br /> Implications and Solutions for United States Companies
              </div>
            </div>
          </Link>
        </div>

        <div style={styles.row}>
          {/* card7 */}
          <Link
            to="/Supreme-court-defers-fundamental"
            style={styles.card}
            onMouseEnter={() => setHovered(7)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder7,
                backgroundImage: `url(${blogee2})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 7 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay7,
                  ...(hovered === 7 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Dec 7, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Supreme Court Defers Fundamental Constitutional Question in
                Upholding Tax on Foreign Business Income
              </div>
            </div>
          </Link>
          {/* card7 */}

          {/* card8 */}
          <Link
            to="/IRS-again-delays-effective-date"
            style={styles.card}
            onMouseEnter={() => setHovered(8)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder8,
                backgroundImage: `url(${blogoverlay})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 8 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay8,
                  ...(hovered === 8 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainerOpposite}>
                <div style={styles.cardDateOpposite}>Dec 4, 2024</div>
                <div style={styles.cardDateLineOpposite}></div>
              </div>
              <div style={styles.cardTitle}>
                IRS again delays effective date of $600 threshold for <br />
                Form 1099-K reporting
              </div>
            </div>
          </Link>
          {/* card8 */}
        </div>

        <div style={styles.row}>
          {/* card 9 */}
          <Link
            to="/navigating-crypto"
            style={styles.card}
            onMouseEnter={() => setHovered(9)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder9,
                backgroundImage: `url(${blogoverlay1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 9 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay9,
                  ...(hovered === 9 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>August 11, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Navigating the Crypto Landscape: What Investors Should Know in
                2024
              </div>
            </div>
          </Link>
          {/* card9 */}

          {/* card 10 */}
          <Link
            to="/financial-services"
            style={styles.card}
            onMouseEnter={() => setHovered(10)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder10,
                backgroundImage: `url(${blogoverlay2})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 10 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay10,
                  ...(hovered === 10 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>August 7, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                The Rise of AI in Financial Services: What Investors Need to
                Know
              </div>
            </div>
          </Link>
          {/* card 10 */}
        </div>

        {/* latestnew blog */}

        <div style={styles.row}>
          {/* card 11 */}
          <Link
            to="/sustainable-finance"
            style={styles.card}
            onMouseEnter={() => setHovered(11)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder,
                backgroundImage: `url(${latestblog1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 11 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay10,
                  ...(hovered === 11 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>August 2, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Sustainable Finance: Boston Financial’s ESG Approach
              </div>
            </div>
          </Link>

          {/* card 11 */}

          {/* card 12 */}
          <Link
            to="/navigating-tax-changes"
            style={styles.card}
            onMouseEnter={() => setHovered(12)}
            onMouseLeave={() => setHovered(null)}
          >
           <div
              style={{
                ...styles.cardImageNoBorder4,
                backgroundImage: `url(${blogoverlay4})`,
              }}
            >
               <div
                className={`cardOverlay ${
                  hovered === 12 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay4,
                  ...(hovered === 12 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>July 31, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Navigating Tax Changes in 2024: What You Need to Know{" "}
              </div>
            </div>
          </Link>
          {/* card 12 */}

          {/* card 13 */}
          <Link
            to="/the-future-of-accouting"
            style={styles.card}
            onMouseEnter={() => setHovered(13)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder5,
                backgroundImage: `url(${blog11})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 13 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay5,
                  ...(hovered === 13 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainerOpposite}>
                <div style={styles.cardDateOpposite}>July 30, 2024</div>
                <div style={styles.cardDateLineOpposite}></div>
              </div>
              <div style={styles.cardTitle}>
                The Future of Accounting Technology: Navigating Digital
                <br /> Transformation
              </div>
            </div>
          </Link>
          {/* card 13 */}
        </div>
      </div>
    </>
  );
};

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column", // Stack rows vertically
    alignItems: "center",
  },
  row: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardImage: {
    width: "780px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomRightRadius: "39%",
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImage6: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight: "0px",
  },
  cardImageSmall: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: "40%",
    position: "relative",
    transition: "transform 0.3s ease",
    marginLeft: "0px",
  },
  cardImageNoBorder: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder10: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder9: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder4: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: "45%",
    transition: "transform 0.3s ease",
  },

  cardImageNoBorder7: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder5: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder8: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: "45%",
    transition: "transform 0.3s ease",
  },
  cardOverlay1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderBottomRightRadius: "39%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay6: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '39%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay2: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderBottomLeftRadius: "40%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay3: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay10: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay9: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // borderBottomRightRadius: '38%',
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay4: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopRightRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay7: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopRightRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay5: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  cardOverlay8: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "45%",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
  overlayVisible: {
    backgroundColor: "rgba(0, 112, 255, 0.61)",
  },
  cardDateContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDate: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLine: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginLeft: "10px",
  },
  cardTitle: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardTitle2: {
    position: "absolute",
    bottom: "40px",
    left: "50px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight: "600",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardDateContainerOpposite: {
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDateOpposite: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLineOpposite: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginRight: "10px",
  },
};

export default BlogTwo;
