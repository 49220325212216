import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import goalnew from '../../assets/FlipImages/goalimg.png';
import bggoal from '../../assets/FlipImages/bggoal.png';

const Visionmissiongoal = () => {
  const [activeTab, setActiveTab] = useState("vision");

  const renderContent = () => {
    switch (activeTab) {
      case "vision":
        return (
          <p className="leading-relaxed">
            At the core of our success story lies the unwavering commitment to excellence, which permeates every interaction and is fortified by our five pillars: advice, outsourcing services, domain knowledge, processes, and technology.
          </p>
        );
      case "mission":
        return (
          <p className="leading-relaxed">
            To be a leading outsourcing and advisory partner for the SME sector worldwide that provides analytical information, technology, and domain expertise quickly and cost effectively.
          </p>
        );
      case "quality statement":
        return (
          <p className="leading-relaxed">
            Excel in providing financial outsourcing, consulting and advisory services leveraging our expertise and technology-driven process leading to customer and partner delight.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <section
      className="text-gray-600 body-font overflow-hidden"
      style={{
        backgroundImage: `url(${bggoal})`,
        backgroundSize: "contain",
        backgroundPosition: "top left",
        backgroundRepeat: "no-repeat",
        padding: "20px",
      }}
    >
      <Container className="px-5 py-24 mx-auto">
        <Row className="lg:w-4/5 mx-auto flex flex-wrap align-items-center">
          {/* Text Section */}
          <Col
            lg={6}
            xs={12}
            className="d-flex flex-column justify-content-center text-center text-lg-start mb-6 lg:mb-0"
            style={{
              textAlign: "center",
            }}
          >
            <h2
              className="unique-display-4 unique-font-weight-bold unique-display-4-custom"
              style={{ color: "#072f5f", fontSize: "35px", fontWeight: "bold" }}
            >
              Our Vision & Mission
            </h2>

            <div
              className="d-flex justify-content-center justify-content-lg-start mt-2 mb-2"
            >
              <span
                className={`flex-grow py-2 text-lg px-1 cursor-pointer ${
                  activeTab === "vision" ? "text-indigo-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("vision")}
                style={{
                  borderBottom: activeTab === "vision" ? "2px solid #072f5f" : "2px solid #d1d5db",
                  marginRight: "40px",
                  cursor: "pointer",
                }}
              >
                Vision
              </span>

              <span
                className={`flex-grow py-2 text-lg px-1 cursor-pointer ${
                  activeTab === "mission" ? "text-indigo-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("mission")}
                style={{
                  borderBottom: activeTab === "mission" ? "2px solid #072f5f" : "2px solid #d1d5db",
                  marginRight: "40px",
                  cursor: "pointer",
                }}
              >
                Mission
              </span>

              <span
                className={`flex-grow py-2 text-lg px-1 cursor-pointer ${
                  activeTab === "quality statement" ? "text-indigo-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("quality statement")}
                style={{
                  borderBottom: activeTab === "quality statement" ? "2px solid #072f5f" : "2px solid #d1d5db",
                  marginRight: "40px",
                  cursor: "pointer",
                }}
              >
                Quality Statement
              </span>
            </div>

            <div
              className="content-area"
              style={{
                minHeight: "150px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {renderContent()}
            </div>
          </Col>

          {/* Image Section */}
          <Col
            lg={6}
            xs={12}
            className="d-flex justify-content-center align-items-center"
            style={{ textAlign: "center" }}
          >
            <img
              alt="goal"
              className="object-cover rounded"
              src={goalnew}
              style={{ width: "auto", height: "400px" }}
            />
          </Col>
        </Row>
      </Container>

      {/* Internal CSS for Mobile View */}
      <style>
        {`
          @media (max-width: 768px) {
            .content-area {
              text-align: center;
              margin-bottom: 20px;
            }

            .d-flex.justify-content-center.align-items-center {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .unique-display-4 {
              text-align: center;
            }
          }
        `}
      </style>
    </section>
  );
};

export default Visionmissiongoal;
