import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import scale from "../../assets/images/blogs/scale.png";
import virtual1 from "../../assets/images/blogs/virtual1.png";
import tax7 from "../../assets/images/blogs/tax7.png";
import blog7 from '../../assets/images/blogs/7.png'
import blog10 from '../../assets/images/blogs/10.png'
import "../BlogOne.css";

const RecentBlogs = () => {
  const [hovered, setHovered] = useState(null);

  return (
    <div className="mb-50 mt-20" style={styles.cardContainer}>
      
          <div className="pb-2 mb-5">
          <h3 className="text-center" style={{ color:'#072f5f', display: 'inline', fontWeight:'bold', fontSize:'30px' }}>Insights from Boston Financial</h3>
          </div>

          <div style={styles.row}>
          {/* card1 */}
          <Link
            to="/virtual-cpa-services"
            style={styles.card}
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImage,
                backgroundImage: `url(${virtual1})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 1 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay1,
                  ...(hovered === 1 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Jan 14, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Virtual CPA Services: How to Maximize Your Business's
                <br /> Financial Health Remotely
              </div>
            </div>
          </Link>
          {/* card1 */}

          {/* card2 */}
          <Link
            to="/cryptocurreny-taxes-explained"
            style={styles.card}
            onMouseEnter={() => setHovered(2)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageSmall,
                backgroundImage: `url(${tax7})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 2 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay2,
                  ...(hovered === 2 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Jan 5, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle2}>
                Cryptocurrency Taxes Explained: <br /> A CPA’s Guide to
                Navigating the Digital Currency Landscape
              </div>
            </div>
          </Link>
          {/* card2 */}
        </div>

        <div style={styles.row}>
          {/* card3 */}
          <Link
            to="/from-seed-to-scale"
            style={styles.card}
            onMouseEnter={() => setHovered(3)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder,
                backgroundImage: `url(${scale})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 3 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay3,
                  ...(hovered === 3 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Dec 30, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                From Seed to Scale:
                <br /> How Pune's CAs are Fuelling the City's Startup Ecosystem
              </div>
            </div>
          </Link>

          {/* card3 */}

          {/* card4 */}
          <Link
            to="/annual-inflation-adjustments"
            style={styles.card}
            onMouseEnter={() => setHovered(4)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder4,
                backgroundImage: `url(${blog10})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 4 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay4,
                  ...(hovered === 4 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainer}>
                <div style={styles.cardDate}>Dec 20, 2024</div>
                <div style={styles.cardDateLine}></div>
              </div>
              <div style={styles.cardTitle}>
                Annual Inflation Adjustments for Tax Year 2025: What Boston{" "}
                <br /> Taxpayers Should Know
              </div>
            </div>
          </Link>
          {/* card4 */}

          {/* card5 */}
          <Link
            to="/audited-financial-report"
            style={styles.card}
            onMouseEnter={() => setHovered(5)}
            onMouseLeave={() => setHovered(null)}
          >
            <div
              style={{
                ...styles.cardImageNoBorder5,
                backgroundImage: `url(${blog7})`,
              }}
            >
              <div
                className={`cardOverlay ${
                  hovered === 5 ? "overlayVisible" : ""
                }`}
                style={{
                  ...styles.cardOverlay5,
                  ...(hovered === 5 ? styles.overlayVisible : {}),
                }}
              ></div>
              <div style={styles.cardDateContainerOpposite}>
                <div style={styles.cardDateOpposite}>Dec 15, 2024</div>
                <div style={styles.cardDateLineOpposite}></div>
              </div>
              <div style={styles.cardTitle}>
                Meaning of Audited Financial Reports to Businesses
              </div>
            </div>
          </Link>
          {/* card5 */}
        </div>

    </div>
  );
};

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column", // Stack rows vertically
    alignItems: "center",
  },
  row: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  card: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
  },
  cardImage: {
    width: "780px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomRightRadius: '39%',
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight:'0px',
  },
  cardImage6: {
    width: "1200px",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // borderBottomRightRadius: '39%',
    // clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
    position: "relative",
    transition: "transform 0.3s ease",
    marginRight:'0px',
  },
  cardImageSmall: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: '40%',
    position: "relative",
    transition: "transform 0.3s ease",
    marginLeft:'0px',
  },
  cardImageNoBorder: {
    width: "400px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  cardImageNoBorder4: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder7: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopRightRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder5: {
    width: "380px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardImageNoBorder8: {
    width: "590px",
    height: "410px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    borderTopLeftRadius: '45%',
    transition: "transform 0.3s ease",
  },
  cardOverlay1: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderBottomRightRadius: '39%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay6: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // borderBottomRightRadius: '39%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay2: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderBottomLeftRadius: '40%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay3: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // borderBottomRightRadius: '38%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay4: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopRightRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay7: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopRightRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay5: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  cardOverlay8: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderTopLeftRadius: '45%',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  },
  overlayVisible: {
    backgroundColor: 'rgba(0, 112, 255, 0.61)',
},
  cardDateContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDate: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLine: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginLeft: "10px",
  },
  cardTitle: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight:'600',
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardTitle2: {
    position: "absolute",
    bottom: "40px",
    left: "50px",
    right: "10px",
    color: "white",
    fontSize: "22px",
    fontWeight:'600',
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
  },
  cardDateContainerOpposite: {
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardDateOpposite: {
    color: "white",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  cardDateLineOpposite: {
    flexGrow: 1,
    height: "2px",
    background: "white",
    marginRight: "10px",
  },

  
}

export default RecentBlogs;
