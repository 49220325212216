const styles = {
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    heading: {
      color: '#072f5f',
      display: 'inline',
      fontWeight: 'bold',
      fontSize: '30px'
    },
    row: {
      display: "flex",
      gap: "20px",
      marginBottom: "20px",
    },
    card: {
      position: "relative",
      overflow: "hidden",
      cursor: "pointer",
    },
    cardImage: {
      width: "780px",
      height: "410px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderBottomRightRadius: '39%',
      clipPath: "polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%)",
      position: "relative",
      transition: "transform 0.3s ease",
    },
    cardImageSmall: {
      width: "400px",
      height: "410px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderBottomLeftRadius: '40%',
      position: "relative",
      transition: "transform 0.3s ease",
    },
    cardImageNoBorder: {
      width: "400px",
      height: "410px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    },
    cardImageNoBorder4: {
      width: "380px",
      height: "410px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      borderTopRightRadius: '45%',
      transition: "transform 0.3s ease",
    },
    cardImageNoBorder5: {
      width: "380px",
      height: "410px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      borderTopLeftRadius: '45%',
      transition: "transform 0.3s ease",
    },
    cardOverlay1: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderBottomRightRadius: '39%',
      backgroundColor: 'transparent',
      transition: 'background-color 0.3s ease',
    },
    cardOverlay2: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderBottomLeftRadius: '40%',
      backgroundColor: 'transparent',
      transition: 'background-color 0.3s ease',
    },
    cardOverlay3: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      transition: 'background-color 0.3s ease',
    },
    cardOverlay4: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderTopRightRadius: '45%',
      backgroundColor: 'transparent',
      transition: 'background-color 0.3s ease',
    },
    cardOverlay5: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderTopLeftRadius: '45%',
      backgroundColor: 'transparent',
      transition: 'background-color 0.3s ease',
    },
    overlayVisible: {
      backgroundColor: 'rgba(0, 112, 255, 0.61)',
    },
    cardDateContainer: {
      position: "absolute",
      top: "10px",
      left: "10px",
      display: "flex",
      alignItems: "center",
    },
    cardDate: {
      color: "white",
      background: "rgba(0, 0, 0, 0.5)",
      padding: "5px 10px",
      borderRadius: "5px",
    },
    cardDateLine: {
      flexGrow: 1,
      height: "2px",
      background: "white",
      marginLeft: "10px",
    },
    cardTitle: {
      position: "absolute",
      bottom: "10px",
      left: "10px",
      right: "10px",
      color: "white",
      fontSize: "22px",
      fontWeight: '600',
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
    },
    cardTitle2: {
      position: "absolute",
      bottom: "40px",
      left: "50px",
      right: "10px",
      color: "white",
      fontSize: "22px",
      fontWeight: '600',
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
    },
    cardDateContainerOpposite: {
      position: "absolute",
      top: "10px",
      right: "10px",
      display: "flex",
      alignItems: "center",
    },
    cardDateOpposite: {
      color: "white",
      background: "rgba(0, 0, 0, 0.5)",
      padding: "5px 10px",
      borderRadius: "5px",
    },
    cardDateLineOpposite: {
      flexGrow: 1,
      height: "2px",
      background: "white",
      marginRight: "10px",
    },
  }
  
  export default styles;