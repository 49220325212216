import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import ankush from "../../../../assets/images/teams/ankush.jpg";

const AnkushBirewar = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: "1%" }}>
              <img
                style={{
                  borderRadius: "10px",
                  width: "300px",
                  height: "340px",
                }}
                className="img-fluid"
                src={ankush}
                alt="ankush"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3
              className="text-uppercase text-dark title"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              Ankush Birewar
            </h3>
            <div>
              <span
                className="text-uppercase text-dark"
                style={{ fontSize: "16px", fontWeight: 600 }}
              >
                VP - Operations
              </span>
              <a
                style={{
                  color: "#fff",
                  marginLeft: "10px",
                  backgroundColor: "#0072b1",
                  padding: "4px",
                  borderRadius: "5px",
                }}
                href="https://www.linkedin.com/in/ankush-birewar-12873054/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ color: "#fff" }}
                />
              </a>
            </div>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Ankush is a Chartered Accountant (CA) and Commerce graduate with
              decades plus years of experience. He is adept at handling clients
              with multi-Jurisdictional presence and is well conversant with
              IFRS, US GAAP, India GAAP, State and Federal Compliances.
              Valuation, Due Diligence, Technical Accounting are his forte. His
              strong analytical skills with the ability to interpret complex
              information quickly and precisely is an asset.
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              As VP – Operations at Boston Financial Advisory Group, he manages
              F&A Processes and is instrumental in spearheading new segments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnkushBirewar;
