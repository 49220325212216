import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import blogee2 from '../../assets/images/blogs/blogee2.jpg'
import blogee3 from '../../assets/images/blogs/3.jpg'
import blogoverlay from '../../assets/images/blogs/blogoverlay.png';
import blog7 from '../../assets/images/blogs/7.png'
import blog10 from '../../assets/images/blogs/10.png'
import styles from "./styles";
const RecentBlogs = () => {
  const [hovered, setHovered] = useState(null);

  return (
    <div className="blog-container" style={styles.cardContainer}>
      <div className="pb-2 mb-5">
        <h3 className="text-center blog-title" style={styles.heading}>
          Insights from Boston Financial
        </h3>
      </div>

      <div style={styles.row} className="blog-row">
        <Link
          to='/annual-inflation-adjustments'
          style={styles.card}
          className="blog-card"
          onMouseEnter={() => setHovered(1)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImage,
              backgroundImage: `url(${blog10})`,
            }}
            className="card-image"
          >
            <div
              className={`cardOverlay ${hovered === 1 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay1,
                ...(hovered === 1 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 20, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle}>
              Annual Inflation Adjustments for Tax Year 2025: What Boston Taxpayers Should Know  
            </div>
          </div>
        </Link>

        <Link
          to='/audited-financial-report'
          style={styles.card}
          className="blog-card"
          onMouseEnter={() => setHovered(2)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageSmall,
              backgroundImage: `url(${blog7})`,
            }}
            className="card-image"
          >
            <div
              className={`cardOverlay ${hovered === 2 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay2,
                ...(hovered === 2 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 15, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle2}>
              Meaning of Audited Financial Reports to Businesses
            </div>
          </div>
        </Link>
      </div>

      <div style={styles.row} className="blog-row">
        <Link
          to='/increasing-incidence-of-financial'
          style={styles.card}
          className="blog-card"
          onMouseEnter={() => setHovered(3)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageNoBorder,
              backgroundImage: `url(${blogee3})`,
            }}
            className="card-image"
          >
            <div
              className={`cardOverlay ${hovered === 3 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay3,
                ...(hovered === 3 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 11, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle}>
              The Increasing Incidence of Financial Statement Retractions: Implications and Solutions for United States Companies 
            </div>
          </div>
        </Link>

        <Link
          to='/Supreme-court-defers-fundamental'
          style={styles.card}
          className="blog-card"
          onMouseEnter={() => setHovered(4)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageNoBorder4,
              backgroundImage: `url(${blogee2})`,
            }}
            className="card-image"
          >
            <div
              className={`cardOverlay ${hovered === 4 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay4,
                ...(hovered === 4 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainer}>
              <div style={styles.cardDate}>Dec 7, 2024</div>
              <div style={styles.cardDateLine}></div>
            </div>
            <div style={styles.cardTitle}>
              Supreme Court Defers Fundamental Constitutional Question in Upholding Tax on Foreign Business Income
            </div>
          </div>
        </Link>

        <Link
          to='/IRS-again-delays-effective-date'
          style={styles.card}
          className="blog-card"
          onMouseEnter={() => setHovered(5)}
          onMouseLeave={() => setHovered(null)}
        >
          <div
            style={{
              ...styles.cardImageNoBorder5,
              backgroundImage: `url(${blogoverlay})`,
            }}
            className="card-image"
          >
            <div
              className={`cardOverlay ${hovered === 5 ? 'overlayVisible' : ''}`}
              style={{
                ...styles.cardOverlay5,
                ...(hovered === 5 ? styles.overlayVisible : {}),
              }}
            ></div>
            <div style={styles.cardDateContainerOpposite}>
              <div style={styles.cardDateOpposite}>Dec 4, 2024</div>
              <div style={styles.cardDateLineOpposite}></div>
            </div>
            <div style={styles.cardTitle}>
              IRS again delays effective date of $600 threshold for Form 1099-K reporting
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default RecentBlogs;