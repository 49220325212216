import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import blog14 from "../../assets/images/blogs/blog14.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const FourthBlog = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
      @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold" }}
            >
              The Rise of AI in Financial Services: <br /> What Investors Need
              to Know
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start pl-4 pr-4"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep">
                      In recent years, artificial intelligence (AI) has emerged
                      as a game-changing technology across various industries,
                      and the financial services sector is no exception. As an
                      investor, understanding the impact of AI on finance can
                      help you make more informed decisions and potentially
                      identify new opportunities. Let's explore how AI is
                      reshaping the financial landscape and what it means for
                      you.{" "}
                    </p>
                  </div>
                  {/* <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                     
                    </small>
                  </div> */}
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        AI's Growing Influence in Finance :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        The adoption of AI in financial services has been
                        rapidly accelerating. Searches for "AI in finance" have
                        surged by 525% over the past 24 months, indicating a
                        growing interest and recognition of its potential 1.
                        This trend is not just hype – AI is already making
                        significant contributions to various aspects of the
                        financial industry.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Key Areas Where AI is Making an Impact :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Fraud Detection: AI excels at identifying patterns,
                        making it an ideal tool for spotting fraudulent
                        transactions. This enhanced security can protect your
                        investments and personal financial information{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Credit Scoring and Underwriting :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        AI can quickly analyze vast amounts of data to assess
                        lending risks for individuals and businesses. This could
                        lead to more accurate and fair credit decisions.{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Market Analysis :
                    </small>
                  </div>
                  <br />
                  <p className="text-dark servicep" style={{textAlign:'justify'}}>
                    Financial professionals are using AI tools like Claude and
                    ChatGPT to analyze massive datasets and identify market
                    opportunities. This could potentially lead to more informed
                    investment strategies.{" "}
                  </p>
                  <img
                    src={blog14}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Regulatory Compliance :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        When properly trained, AI can help identify and report
                        potential regulatory and compliance issues, reducing
                        risks for financial institutions and investors alike.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Knowledge Extraction :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        AI can "chat" with complex financial documents, such as
                        Form S-1 filings, to extract valuable insights that
                        might otherwise be overlooked 1. What This Means for
                        Investors As an investor, the rise of AI in finance
                        presents both opportunities and challenges: Enhanced
                        Decision-Making: AI-powered tools can provide more
                        sophisticated market analysis and risk assessment,
                        potentially leading to better-informed investment
                        decisions.{" "}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Improved Customer Experience :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Many financial institutions are using AI to offer
                        personalized services and recommendations, which could
                        enhance your overall experience as a customer. New
                        Investment Opportunities: Companies developing or
                        effectively implementing AI in finance may present
                        attractive investment prospects. Changing Landscape: As
                        AI reshapes the financial industry, it's important to
                        stay informed about how these changes might affect your
                        investment strategy and the companies you invest in.{" "}
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Looking Ahead :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        While AI in finance is still in its early stages, its
                        potential is immense. As an investor, staying informed
                        about these developments can help you navigate the
                        changing financial landscape more effectively. However,
                        it's crucial to remember that while AI can be a powerful
                        tool, it should complement, not replace, human judgment
                        and expertise.{" "}
                      </li>
                    </ul>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign:'justify' }}
                  >
                    <li>
                      At Boston Financial Advisory Group, we're committed to
                      helping our clients understand and leverage these
                      technological advancements. We encourage you to reach out
                      to us to discuss how AI and other emerging trends might
                      impact your personal financial strategy.{" "}
                    </li>
                  </ul>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FourthBlog;
