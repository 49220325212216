import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import vaman from "../../../../assets/images/teams/vamanLD.jpeg";

const Vamanld = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: "1%" }}>
              <img
                style={{
                  borderRadius: "10px",
                  width: "320px",
                  height: "320px",
                }}
                className="img-fluid"
                src={vaman}
                alt="vaman"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3
              className="text-uppercase text-dark title"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              Vaman
            </h3>
            <div>
              <span
                className="text-uppercase text-dark"
                style={{ fontSize: "16px", fontWeight: 600 }}
              >
                CTO
              </span>
              <a
                style={{
                  color: "#fff",
                  marginLeft: "10px",
                  backgroundColor: "#0072b1",
                  padding: "4px",
                  borderRadius: "5px",
                }}
                // href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ color: "#fff" }}
                />
              </a>
            </div>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Strategic Technology Leader | Expert in IT Operations,
              Cybersecurity, and Team Leadership
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              With over 27 years of experience, I am a results-driven technology
              leader with expertise in Enterprise IT Operations, Network
              Operations Centers (NOCs), Security Operations Centers (SOCs),
              Cloud Operations (DevOps)and Information Security Offices. I have
              a proven track record of designing and implementing innovative
              technology solutions that enhance operational efficiency,
              strengthen security, and drive business success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vamanld;
