import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import blog1 from "../assets/images/blogs/blog1.jfif";
import blog2 from "../assets/images/blogs/blog2.jfif";
import blog3 from "../assets/images/blogs/blog15.png";
import blog4 from "../assets/images/blogs/blog5.jfif";
import { Button } from "@mui/material";
import "./BlogOne.css";
import videoBackground from "../assets/video/boston baneer video.mp4";
import flip6 from '../assets/FlipImages/flip6.jfif'
import { Helmet } from "react-helmet";

const BlogOne = () => {
  const blogs = [
    {
      img: blog4,
      date: "Posted On 27 Apr 2022 | admin@bfag",
      title:
        "Boston Financial: Holding SMEs' F&A Strings Right Enough to Let Them Fly",
      link: "https://consultants.siliconindia.com/vendor/boston-financial-holding-smes--fa-strings-right-enough-to-let-them-fly-cid-256.html",
    },
    {
      img: blog3,
      date:"Posted On 12 June 2023 | admin@bfag ",
      title: (
        <>Listed as top 25th accounting firm in Boston <br/> <br/></>),
      link: "https://themanifest.com/accounting/financial/firms/boston",
    },
    {
      img: blog1,
      date: "Posted On 24 March 2020 | admin@bfag",
      title:
        "Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis",
      link: "https://www.prnewswire.com/in/news-releases/tech-driven-accounting-amp-financial-consulting-services-to-help-brands-during-crisis-832660697.html",
    },
    {
      img: flip6,
      date: "Posted On 24 March 2020 | admin@bfag",
      title:
        "Tech-driven Accounting & Financial Consulting Services to Help Brands During Crisis",
      link: "https://www.tmcnet.com/usubmit/2020/03/24/9119676.htm",
    },
  ];

  return (
<>
    <Helmet>
        <title>
        Our Publications - Boston Financial Advisory Group
        </title>
        <meta
          name="description"
          content="Explore BFAG's expert insights on finance, accounting, law & compliance. Access in-depth articles, case studies & updates to stay informed and make better business decisions."
        />
        <meta
          name="keywords"
          content="Financial advisory firm, Financial consulting firm, Tax preparation, Financial consulting company"
        />
      </Helmet>

    <div className="bg-light p-6">
      <div className="container mx-auto pt-50 pb-50">
        <div className="row align-items-center">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="col-lg mb-4 mb-lg-0 text-center">
              <h2
                className="text-uppercase mb-3"
                style={{ fontWeight: "bold", color: "#000" }}
              >
                Our Publications
              </h2>
             
            </div>
          </div>
          <div className="row mt-4">
            {blogs.map((blog, index) => (
              <div className="col-lg-3 col-md-6 mb-4" key={index}>
                <Link to={blog.link} target="_blank" rel="noopener noreferrer">
                  <div
                    className="blog-card bg-white cursor-pointer rounded overflow-hidden shadow-sm position-relative group"
                    style={{ animationDelay: `${index * 0.2}s` }}
                  >
                    <img
                      src={blog.img}
                      alt={`Blog Post ${index + 1}`}
                      className="img-fluid"
                      style={{ height: "320px", width: "auto" }}
                    />
                    <div className="p-3 position-absolute bottom-0 start-0 end-0 bg-white opacity-90">
                      <span
                        className="text-sm text-gray-500 mb-2 d-block"
                        style={{ color: "rgb(7, 47, 95)" }}
                      >
                        {blog.date}
                      </span>
                      <h3
                        className="h5 font-bold text-black-500"
                        style={{ fontSize: "15px", color: "black" }}
                      >
                        {blog.title}
                      </h3>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default BlogOne;
