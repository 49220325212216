import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog9 from '../../assets/images/blogs/9.png'
import blogee4 from "../../assets/images/blogs/blogee4.jpg";

const Annualinflationadjustments = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
<style jsx>{`
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 28px;
  }
  span {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
    font-weight: 500;
  }
  .mainCard {
    height: 20rem;
  }
  .circle {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    color: #072f5f;
  }
  ul {
    padding-left: 17px;
  }
  @media (max-width: 574px) {
    .mTop {
      margin-top: 30px;
    }
    p {
      font-size: 18px;
    }
    .img-fluid {
      min-height: 100px;
    }
  }
  @media (min-width: 992px) {
    /* for banner image */
    .bmTop {
      margin-top: 130px;
    }
  }
      @media (max-width: 574px) {
    .banner-image {
      margin-top: 70px; /* Adjust this value for mobile view */
    }
  }
`}</style>

<section>
  <div
    className="d-flex justify-content-center align-items-center banner-image"
    style={{
      background: `url(${casestudy}) no-repeat center`,
      backgroundSize: "cover",
      height: "200px",
    }}
  >
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h3
        className="text-white text-uppercase text-center"
        style={{ fontWeight: "bold" }}
      >
        Annual Inflation Adjustments for Tax Year 2025: <br /> What Boston
        Taxpayers Should Know
      </h3>
    </div>
  </div>
  <div className="container-fluid">
    <div
      className="d-flex row justify-content-center align-items-start pl-4 pr-4"
      style={{ gap: "2rem" }}
    >
      <div
        className="col-lg-8 mt-50 mb-50 border shadow"
        style={{ padding: "2rem" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div>
            <div
              style={{
                background: "#072f5f",
                display: "inline",
                width: "fit-content",
                padding: "0.2rem 1rem 0.2rem 1rem",
              }}
            >
              <small
                className="text-uppercase text-white"
                style={{ fontWeight: "600", fontSize: "15px" }}
              >
                Introduction
              </small>
            </div>
            <div>
              <div className="mt-15">
                <span
                  className="text-capitalize"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#002249",
                  }}
                ></span>
              </div>
              <p
                className="text-dark servicep"
                style={{ textAlign: "justify" }}
              >
                Last month, the Internal Revenue Service (IRS) announced major
                inflation adjustments for the 2025 tax year. Millions of
                Americans from all walks of life will feel its effects. Boston
                Financial Advisory Group is committed to keeping our clients
                abreast of what's on the horizon.
              </p>
              <p
                className="text-dark servicep"
                style={{ textAlign: "justify" }}
              >
                Let's dive into the major changes and what they mean for you.
              </p>
            </div>
            <div>
              <div className="mt-15">
                <span
                  className="text-capitalize"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    color: "#002249",
                  }}
                >
                  Key Changes for Tax Year 2025{" "}
                </span>
              </div>
            </div>
            <ul className="text-dark servicep">
              <li className="mt-2">
                Standard Deduction Increases Single filers and married
                individuals filing separately: $15,000 (up from $14,600 in
                2024)
              </li>
              <li className="mt-2">
                Married couples filing jointly: $30,000 (an $800 increase from
                2024)
              </li>
              <li className="mt-2">
                Heads of household: $22,500 (up from $21,900 in 2024)
              </li>
              <li className="mt-4">
                These increases allow taxpayers to shield more of their income
                from taxation, potentially leading to lower tax bills for many
                residents.
              </li>
            </ul>

            <div>
              <div className="mt-15">
                <span
                  className="text-capitalize"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    color: "#002249",
                  }}
                >
                  Tax Bracket Adjustments
                </span>
              </div>
              <ul
                className="text-dark servicep"
                style={{ listStyleType: "disc", textAlign: "justify" }}
              >
                <li className="mt-2">
                  All individual income tax brackets have been adjusted upward.
                </li>
                <li className="mt-2">
                        Highest Marginal Income Tax Rate: The highest marginal
                        rate of 37% will apply to: Single taxpayers with income
                        above $626,350.{" "}
                      </li>
                      <li className="mt-2">
                        Joint filers with incomes above $751,600. This
                        adjustment eliminates 'bracket creep,' where inflation
                        pushes taxpayers into higher tax brackets without a real
                        increase in purchasing power.{" "}
                      </li>
                      <li className="mt-2">
                        Capital Gains Tax Rates: Tax rates for long-term capital
                        gains have also changed:
                      </li>
                      <li className="mt-2">
                        0 percent applies to single filers with incomes below
                        $48,350 and joint filers with incomes below $96,700.{" "}
                      </li>
                      <li className="mt-2">
                        15 percent applies to income below $533,400 for single
                        filers and below $600,050 for joint filers.{" "}
                      </li>
                      <li className="mt-2">
                        20 percent applies to income above those thresholds.
                        Additionally, the 3.8% Net Investment Income Tax
                        continues to apply to the taxpayer's adjusted gross
                        income above $200,000 (or $250,000 for married filing
                        jointly).
                      </li>
                      <li className="mt-2">
                        Estate and Gift Tax Exclusions: The single level of
                        estate and gift tax exclusion is $13,990,000 (up from
                        $13,610,000 in 2024). The annual contribution limit for
                        401(k) plans is $23,500 for 2025.These all represent
                        incredible planning opportunities for high-net-worth
                        Residents.
                      </li>
                      <li className="mt-2">
                        Retirement Account Contributions: The annual
                        contribution limit under the 401(k) plan is $23,500 for
                        2025.
                      </li>
              </ul>
              <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        The traditional and Roth IRA annual contribution limit
                        for 2025 is $7,000, but an extra $1,000 catch-up
                        contribution applies to participants aged 50 and older.{" "}
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li className="mt-2">
                        Tax Bracket Shifts: Because of the upward adjustment of
                        tax brackets, some residents may be bumped into a lower
                        bracket. This may lead to a reduced effective tax rate
                        on their income.
                      </li>

                      <li className="mt-2">
                        Retirement Savings Opportunities: The more contribution
                        limit for 401(k) plans is an excellent opportunity for
                        employees to save more toward retirement while
                        potentially reducing taxable income.
                      </li>
                      <li className="mt-2">
                        For Small Businesses: Payroll Adjustments: Boston small
                        business owners should consult with their payroll
                        departments or providers to make necessary adjustments
                        for proper employee withholding.{" "}
                      </li>
                      <li className="mt-2">
                        Retirement Plan Offerings: It would be smart to expand
                        the retirement plan options available at your company to
                        take advantage of the increased contribution limits.
                        This could be an attractive benefit for the hiring and
                        retaining of the best talent in Boston's competitive job
                        market.{" "}
                      </li>
                      <li className="mt-2">
                        Business Structure Review: Given the changes in tax
                        brackets and deductions, it's wise to review your
                        business structure to ensure it's still the most
                        tax-effective option.{" "}
                      </li>

                      <li className="mt-2">
                        For Gig Economy Workers Easy Tax Filing: The increased
                        standard deduction can make tax filing easier for many
                        gig workers. However, it's important to account for
                        income and expenses.{" "}
                      </li>
                      <li className="mt-2">
                        Retirement Savings Focus: For Gig workers, this rise in
                        contribution limits for IRAs serves as the chance to
                        build wealth during retirement, since they are left to
                        their own devices regarding the employer's contribution
                        plans.{" "}
                      </li>
                      <li className="mt-2">
                        Quarterly Estimated Taxes: With the higher deductions
                        and adjusted brackets, gig workers will need to rethink
                        their quarterly estimated tax payments to avoid
                        overpaying.{" "}
                      </li>
                      <li className="mt-2">
                        Tax Compliance Strategies Proactive Planning: One cannot
                        wait for the arrival of tax season. It is good to
                        consult a CPA to forecast your tax liability for the
                        year 2025 and to make necessary changes throughout the
                        year.{" "}
                      </li>
                      <li className="mt-2">
                        In fact, detailed record-keeping has become essential,
                        especially for small business owners and gig workers,
                        due to the changing thresholds and deductions. It's wise
                        to prepare for a quarterly review with your financial
                        advisor to keep you on course with your tax strategy,
                        and make the necessary changes where life changes or
                        financial performance will require such changes.{" "}
                      </li>
                      <li className="mt-2">
                        There is also a need to be in the know of these changes
                        and how they will work. Attend local workshops or
                        webinars put on by financial advisory groups in Boston
                        to better understand how these changes affect your
                        personal situation.{" "}
                      </li>
                    </ul>
            </div>
            <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        color: "#002249",
                      }}
                    >
                      Conclusion :
                    </span>
                  </div>
                  <img
                    src={blog9}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "350px",
                      height: "270px",
                    }}
                  />
                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        The Annual Inflation Adjustments for Tax Year 2025 bring
                        both promise and challenge to the taxpayer. By
                        comprehending these modifications and collaborating
                        closely with a knowledgeable financial advisor based in
                        USA, one can optimize their tax strategy and financial
                        planning for the forthcoming year.
                      </li>
                      <li>
                        At Boston Financial Advisory Group, we are devoted to
                        assisting our clients in effectively navigating these
                        changes. Our team of expert Certified Public Accountants
                        (CPAs) and financial advisors is available to offer
                        personalized guidance tailored to your distinctive
                        financial circumstances It is advisable not to postpone
                        tax planning until the tax season arrives. Call the
                        Boston Financial Advisory Group today to schedule a
                        consultation so you can be assured to be making the most
                        out of these new tax adjustments.
                      </li>
                      <li>
                        Schedule your tax planning appointment with our Boston
                        CPA team at: https://bostonfagroup.com/
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default Annualinflationadjustments;
