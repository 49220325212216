import React, { useState, useEffect } from "react";
import consulting from "../../../assets/images/service/consulting.png";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const InternalAudit = () => {
    const [activeKey1, setActiveKey1] = useState("0");
    const [activeKey2, setActiveKey2] = useState("0");
    const [hoveredLink, setHoveredLink] = useState(null);
    const [isIndia, setIsIndia] = useState(false);
    const [isUSA, setIsUSA] = useState(false);
    const [isUAE, setIsUAE] = useState(false);

    useEffect(() => {
      const selectedCountry = localStorage.getItem("selectedCountry");
      setIsIndia(selectedCountry === "India");
      setIsUSA(selectedCountry === "USA");
      setIsUAE(selectedCountry === "UAE" );
    }, []);
  
    const customToggle1 = (eventKey) => {
      setActiveKey1(eventKey === activeKey1 ? null : eventKey);
    };
  
    const customToggle2 = (eventKey) => {
      setActiveKey2(eventKey === activeKey2 ? null : eventKey);
    };
  
    const headerStyle = {
      color: "#000",
      // textAlign: "center",
      fontWeight: "600",
    };
  
    const linkStyle = (link) => ({
      textDecoration: "none",
      color: "#000",
      fontWeight: hoveredLink === link ? "bold" : "normal",
    });
  
    const handleMouseEnter = (link) => {
      setHoveredLink(link);
    };
  
    const handleMouseLeave = () => {
      setHoveredLink(null);
    };
  
    return (
      <>

<Helmet>
        <title>
        Internal Audit Excellence | Trusted Accounting & Financial Consulting Services        </title>
        <meta
          name="description"
          content="Enhance your financial integrity with our internal audit expertise. Our accounting consultants deliver comprehensive financial accounting services. Leverage our consulting and advisory services for sustainable business growth."
        />
        <meta
          name="keywords"
          content="Accounting services, Financial accounting services, Consulting and advisory services, Accounting services, Accounting consultant"
        />
      </Helmet>

        <style>{`
                .card {
                  // background: #072F5F1A;
                  border: none;
                  height: 500px
                }
        
                li {
                  padding-bottom: 0.8rem;
                  cursor: pointer;
                }
        
                li:hover {
                  font-weight: 600;
                }
        
                .faq-accordion-3 .accordion .card .card-header a {
                  line-height: 60px;
                  padding: 0 0 0 20px;
                  text-decoration: none;
                  font-weight: 600;
                  font-size: 16px;
                  width: 100%;
                  color: #072F5F;
                  position: relative;
                }
        
                p {
                  font-weight: 500;
                }
        
                h1 {
                  font-size: 48px !important;
                  border-bottom: 3px solid white;
                  display: inline-block;
                }
        
                h4 {
                  font-size: 18px;
                }
        
                h3 {
                  font-size: 28px;
                }
        
                .accordion-container {
                  max-height: 500px;
                  overflow-y: auto;  
                  margin-bottom: 2rem; 
                }
        
                .page-content {
                  display: flex;
                  flex-direction: column;
                  min-height: 100vh;
                }
        
                .main-content {
                  flex: 1;
                }
        
                /* Media query for responsiveness */
                @media (max-width: 768px) {
                  .accordion-container {
                    max-height: 300px;
                  }
        
                  .banner-image {
                    height: 300px;
                  }
        
                  h3 {
                    font-size: 24px;
                  }
        
                  h1 {
                    font-size: 36px;
                  }
                }
              `}</style>
  
        <section className="page-content">
          <div
            className="d-flex justify-content-center align-items-center banner-image"
            style={{
              background: `url(${consulting}) no-repeat center`,
              backgroundSize: "cover",
              height: "430px",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3
                className="text-white text-uppercase"
                style={{ fontWeight: "bold" }}
              >
              Audit & Assurance Support (UAE)
              </h3>
            </div>
          </div>
          <div className="main-content">
            <div className="d-flex justify-content-center align-items-center banner-image">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h3 className="text-white text-uppercase text-center">
                Audit & Assurance Support (UAE)
                </h3>
              </div>
            </div>
            <div className="container-fluid d-flex justify-content-center align-content-center bg-white pb-70">
              <div className="row w-100">
                <div className="col-lg-6" style={{ width: "96%" }}>
                  <div className="about-faq-area-custom horizon-hrms bg_cover">
                    <div className="faq-accordion-3 pt-0 accordion-container">
                      <div className="accordion" id="accordionExample1">
                        <div className="card" style={{ width: "100%" }}>
                          <Accordion
                            activeKey={activeKey1}
                            onSelect={customToggle1}
                            flush
                          >
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Accounting and Transaction Processing
                                </span>
                              </Accordion.Header>
  
                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul">
                                        <li className="shli">
                                          <Link
                                            to="/accountspayable"
                                            style={linkStyle("/accountspayable")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/accountspayable")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i
                                              className="far fa-angle-double-right pt-1 pb-3 pr-1"
                                             
                                            ></i>
                                            Accounts Payable
                                          </Link>
                                        </li>
                                        <li className="shli">
                                          <Link
                                            to="/accountreceivable"
                                            style={linkStyle(
                                              "/accountreceivable"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/accountreceivable"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Accounts Receivable
                                          </Link>
                                        </li>
  
                                        <li className="shli">
                                          <Link
                                            to="/fixed-assets-accounting"
                                            style={linkStyle(
                                              "/fixed-assets-accounting"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/fixed-assets-accounting"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Fixed Assets Accounting
                                          </Link>
                                        </li>
  
                                        <li className="shli">
                                          <Link
                                            to="/financial-reporting"
                                            style={linkStyle(
                                              "/financial-reporting"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/financial-reporting"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Financial Reporting
                                          </Link>
                                        </li>
  
                                        <li className="shli">
                                          <Link
                                            to="/budgeting-and-variance-analysis"
                                            style={linkStyle(
                                              "/budgeting-and-variance-analysis"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/budgeting-and-variance-analysis"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Budgeting & variance analysis
                                          </Link>
                                        </li>
  
                                        <li className="shli">
                                          <Link
                                            to="/reconciliation-service"
                                            style={linkStyle(
                                              "/reconciliation-service"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/reconciliation-service"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Reconciliation Service
                                          </Link>
                                        </li>
  
                                        <li className="shli">
                                          <Link
                                            to="/consolidation-reporting-gaap-conversion"
                                            style={linkStyle(
                                              "/consolidation-reporting-gaap-conversion"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/consolidation-reporting-gaap-conversion"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Consolidation Reporting & GAAP
                                           
                                          </Link>
                                        </li>
                                        <span style={{marginLeft:'19px', fontWeight:'500', color:'black'}}> Conversion</span>

                                        {!isIndia && (
                                        <li className="shli">
                                            <Link
                                              to="/Small-business-accounting"
                                              style={linkStyle(
                                                "/Small-business-accounting"
                                              )}
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  "/Small-business-accounting"
                                                )
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i
                                                className="far fa-angle-double-right pt-1 pb-3 pr-1" style={{ marginTop:'10px'}}
                                                
                                              ></i>
                                             Small Business Accounting
                                            </Link>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Tax & Regulatory Compliances
                                </span>
                              </Accordion.Header>
  
                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul">
                                     {!isUSA && !isUAE && (  
                                       <li className="shli">
                                          <Link
                                            to="/income-tax"
                                            style={linkStyle("/income-tax")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/income-tax")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Income Tax
                                          </Link>
                                        </li>
                                        )}
                                        {!isIndia && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Individual-tax-return"
                                            style={linkStyle("/Individual-tax-return")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/Individual-tax-return")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1">
                                            
                                            </i>
                                           Individual Tax Return 
                                          </Link>
                                        </li>
                                        )}
                                          {!isIndia && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Corporate-tax-return"
                                            style={linkStyle("/Corporate-tax-return")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/Corporate-tax-return")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1">
                                            
                                            </i>
                                          Corporate Tax Return 
                                          </Link>
                                        </li>
                                        )}
                                        {!isIndia && !isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/Sales-and-usa-tax"
                                            style={linkStyle("/Sales-and-usa-tax")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/Sales-and-usa-tax")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1">
                                            
                                            </i>
                                         Sales & USA Tax 
                                          </Link>
                                        </li>
                                        )}
                                         {!isUSA && !isUAE && ( 
                                        <li className="shli">
                                          <Link
                                            to="/goods-and-service-tax"
                                            style={linkStyle(
                                              "/goods-and-service-tax"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/goods-and-service-tax"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Goods and Service Tax
                                          </Link>
                                        </li>
                                         )}
                                          {!isUSA && !isUAE && ( 
                                        <li className="shli">
                                          <Link
                                            to="/corporate-secretarial-compliance"
                                            style={linkStyle(
                                              "/corporate-secretarial-compliance"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/corporate-secretarial-compliance"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Corporate Secretarial Compliance
                                          </Link>
                                        </li>
                                          )}
                                           {!isUSA && !isUAE && ( 
                                        <li className="shli">
                                          <Link
                                            to="/Fema-compliance"
                                            style={linkStyle("/Fema-compliance")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/Fema-compliance")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            FEMA Compliance
                                          </Link>
                                        </li>
                                           )}
                                           {!isUSA && !isIndia && ( 
                                        <li className="shli">
                                          <Link
                                            to="/vat-advisory"
                                            style={linkStyle("/vat-advisory")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/vat-advisory")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1" >
                                                
                                            </i>
                                            VAT Advisory
                                          </Link>
                                        </li>
                                           )}
                                            {!isUSA && !isIndia && ( 
                                        <li className="shli">
                                          <Link
                                            to="/corporate-tax-advisory"
                                            style={linkStyle("/corporate-tax-advisory")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/corporate-tax-advisory")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1" >
                                                
                                            </i>
                                            Corporate Tax Advisory
                                          </Link>
                                        </li>
                                           )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                <span style={headerStyle}>Payroll Advisory </span>
                              </Accordion.Header>
  
                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul" style={{marginLeft:'-33px'}}>
                                        {!isUSA && !isUAE && (
                                          <li className="shli">
                                            <Link
                                              to="/payroll-processing"
                                              style={linkStyle(
                                                "/payroll-processing"
                                              )}
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  "/payroll-processing"
                                                )
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                              Payroll - Compensation & Benefit Management
                                             
                                            </Link>
                                          </li>
                                        )}
                                        {!isIndia && (
                                          <li className="shli">
                                            <Link
                                              to="/payroll-and-processing"
                                              style={linkStyle(
                                                "/payroll-and-processing"
                                              )}
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  "/payroll-and-processing"
                                                )
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                              Payroll Processing
                                            </Link>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

{!isUSA && !isIndia && (
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span style={headerStyle}>Audit & Assurance Support (UAE)</span>
                              </Accordion.Header>
  
                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul">
                                       
                                          <li className="shli">
                                            <Link
                                              to="/statutory-audit"
                                              style={linkStyle(
                                                "/statutory-audit"
                                              )}
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  "/statutory-audit"
                                                )
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i className="far fa-angle-double-right pt-1 pb-3 pr-1" ></i>
                                              Statutory Audit for Freezones & Mainland
                                            </Link>
                                          </li>

                                          <li className="shli">
                                            <Link
                                              to="/internal-audit"
                                              style={linkStyle(
                                                "/internal-audit"
                                              )}
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  "/internal-audit"
                                                )
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i className="far fa-angle-double-right pt-1 pb-3 pr-1" style={{fontWeight:'bold'}} ></i>
                                              Internal Audit
                                            </Link>
                                          </li>
                                    
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
)}
                            <Accordion.Item eventKey="4">
                              <Accordion.Header>
                                <span style={headerStyle}>
                                  Consulting & Advisory
                                </span>
                              </Accordion.Header>
  
                              <Accordion.Body>
                                <div
                                  id="collapseSev"
                                  className="collapse show"
                                  aria-labelledby="headingSev"
                                  data-parent="#accordionExample1"
                                >
                                  <div className="card-body bg-white">
                                    <div>
                                      <ul className="text-dark serviceul">
                                      {!isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/business-setup"
                                            style={linkStyle("/business-setup")}
                                            onMouseEnter={() =>
                                              handleMouseEnter("/business-setup")
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Setting up an entity in the India
                                          </Link>
                                        </li>
                                      )}
                                        {!isIndia && !isUSA && (
                                      <li className="shli">
                                        <Link
                                          to="/Bussiness-setup(Uae)"
                                          style={linkStyle("/Bussiness-setup(Uae)")}
                                          onMouseEnter={() =>
                                            handleMouseEnter("/Bussiness-setup(Uae)")
                                          }
                                          onMouseLeave={handleMouseLeave}
                                        >
                                          <i
                                            className="far fa-angle-double-right pt-1 pb-3 pr-1"
                                            
                                          ></i>
                                         Business Set-up
                                        </Link>
                                      </li>
                                      )}
  {!isUAE && (
                                        <li className="shli">
                                          <Link
                                            to="/global-corporate-structuring"
                                            style={linkStyle(
                                              "/global-corporate-structuring"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/global-corporate-structuring"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Global Corporate Structuring
                                          </Link>
                                        </li>
  )}
                                        <li className="shli">
                                          <Link
                                            to="/business-plan-preparation"
                                            style={linkStyle(
                                              "/business-plan-preparation"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/business-plan-preparation"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Business Plan Preparation
                                          </Link>
                                        </li>
                                        <li className="shli">
                                          <Link
                                            to="/business-valuation-service"
                                            style={linkStyle(
                                              "/business-valuation-service"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/business-valuation-service"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Business Valuation
                                          </Link>
                                        </li>
                                        <li className="shli">
                                          <Link
                                            to="/merge-and acquisitions"
                                            style={linkStyle(
                                              "/merge-and acquisitions"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/merge-and acquisitions"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                            Merge and Acquisitions
                                          </Link>
                                        </li>

                                        {!isIndia && !isUSA && (
                                        <li className="shli">
                                          <Link
                                            to="/strategic-cfo-services"
                                            style={linkStyle(
                                              "/strategic-cfo-services"
                                            )}
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                "/strategic-cfo-services"
                                              )
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <i
                                              className="far fa-angle-double-right pt-1 pb-3 pr-1"
                                          
                                            ></i>
                                            Strategic CFO Services
                                          </Link>
                                        </li>
                                      )}

                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            {isIndia && (
                              <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                  <span style={headerStyle}>
                                    Startup Solution
                                  </span>
                                </Accordion.Header>
  
                                <Accordion.Body>
                                  <div
                                    id="collapseSev"
                                    className="collapse show"
                                    aria-labelledby="headingSev"
                                    data-parent="#accordionExample1"
                                  >
                                    <div className="card-body bg-white">
                                      <div>
                                        <ul className="text-dark serviceul">
                                          <li className="shli">
                                            <Link
                                              to="/startup-solution"
                                              style={linkStyle(
                                                "/startup-solution"
                                              )}
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  "/startup-solution"
                                                )
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                              Startup Solution
                                            </Link>
                                          </li>
  
                                          <li className="shli">
                                            <Link
                                              to="/fund-raising"
                                              style={linkStyle("/fund-raising")}
                                              onMouseEnter={() =>
                                                handleMouseEnter("/fund-raising")
                                              }
                                              onMouseLeave={handleMouseLeave}
                                            >
                                              <i className="far fa-angle-double-right pt-1 pb-3 pr-1"></i>
                                              Fund Raising
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="col-lg-8">
                <div
                  style={{
                    marginTop: "1rem",
                    fontWeight: "bold",
                    color: "#002249",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                      color: "#002249",
                      paddingLeft: "20px",
                    }}
                  >
                   Internal Audit
                  </h3>
                  <p
                    className="mb-15 mt-20 text-dark servicep "
                    style={{ paddingLeft: "20px", fontWeight: "400" }}
                  >
BFAG is a leading provider of Internal Audit services. With our deep expertise and knowledge of local regulations, industry best practices, and risk management, we offer comprehensive and reliable internal audit solutions to help businesses enhance their governance, risk management, and control processes. In this article, we will explore BFAG's Internal Audit services, designed to meet the unique needs of organization’s operations.</p>
                
                  <div
                    class="container pt-4"
                    style={{ backgroundColor: "#F0f8ff" }}
                  >
                    <div className="row align-items-start ">
                      <div className="col pt-1 pb-1 ">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span style={headerStyle}>
                              Comprehensive Internal Audit Services
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
BFAG's Internal Audit services are tailored to assist businesses in evaluating and enhancing their internal control systems, risk management frameworks, and operational efficiencies.
</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <span style={headerStyle}>
                              Risk Assessment
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
We conduct a comprehensive risk assessment to identify and prioritize potential risks and vulnerabilities specific to your organization, allowing us to tailor our audit procedures accordingly.</p>                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
  
                    <div class="row align-items-start">
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <span style={headerStyle}>
                            Internal Control Evaluation
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
Our experts assess your internal control systems and processes to identify gaps, weaknesses, and areas for improvement, helping you strengthen your control environment and minimize operational risks.        </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              <span style={headerStyle}>
                              Compliance Review                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
We review your organization's compliance with applicable laws, regulations, and internal policies, ensuring adherence to legal and regulatory requirements and mitigating compliance risks.  </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
  
                    <div class="row align-items-start">
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <span style={headerStyle}>
                              Process Efficiency                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
We assess your business processes to identify inefficiencies, bottlenecks, and areas where operational improvements can be made, helping you streamline operations and enhance productivity. </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <span style={headerStyle}>
                              Fraud Detection and Prevention                          </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
Our internal audit procedures include a thorough assessment of fraud risks and the implementation of controls to detect and prevent fraudulent activities within your organization.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                     
                    </div>

                    <div class="row align-items-start">
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <span style={headerStyle}>
                            Customized Audit Approach                            </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
At BFAG, we understand that every organization is unique, and our Internal Audit services are tailored to meet your specific needs. We work closely with your management team to gain a deep understanding of your business objectives, risks, and operations. This allows us to develop a customized audit approach that addresses your organization's specific challenges and focuses on areas critical to your success.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div class="col pt-1 pb-1">
                        <Accordion>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <span style={headerStyle}>
                              Experienced Professionals and Advanced Tools                          </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                style={{
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  color: "#000",
                                }}
                              >
Our Internal Audit team comprises experienced professionals who possess a strong understanding of industry best practices and local regulations. We leverage advanced audit tools and methodologies to enhance the effectiveness and efficiency of our audit procedures, ensuring a comprehensive and timely delivery of our services.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                     
                    </div>
                  
                   
                    <br />
                  </div>
                  <p
                    className="mb-15 mt-20 text-dark servicep"
                    style={{ paddingLeft: "20px", fontWeight: "400" }}
                  >
BFAG's Internal Audit services are designed to help organizations enhance their governance, risk management, and control processes. With our comprehensive approach, customized audit methodology, and experienced professionals, we deliver reliable and valuable insights to improve your internal control systems, identify and mitigate risks, streamline operations, and ensure compliance with applicable laws and regulations. Trust BFAG to be your partner in strengthening your organization's internal processes and achieving your strategic objectives. Contact us today.
</p>                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

export default InternalAudit