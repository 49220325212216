import React, { useState } from "react";
import "./Careers.css";
import cib from "../../../assets/images/career/CiB-Collage.jpg";
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import vlogger from "../../../assets/images/career/vlogger.gif";
import file from "../../../assets/images/career/file.gif";
import growth from "../../../assets/images/career/growth.gif";
import analytics from "../../../assets/images/career/analytics.gif";
import {
  TextField,
  Button,
  Alert,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Careers = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    current_location: "",
    yoexp: "",
    highest_qualification: "",
  });

  const jobList = [];

  const [submitted, setSubmitted] = useState(false);
  const [resultTrue, setResultTrue] = useState(false);
  const [resultFalse, setResultFalse] = useState(false);

  const reCaptcha = () => {
    // Implement your logic to refresh or generate a new captcha here
    console.log("Refreshing captcha...");
    // Example: You might update state or perform an action to refresh captcha UI
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Example: Replace with actual API call or validation logic
      const response = await fetch("your-api-endpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        setResultTrue(true);
        setResultFalse(false);
      } else {
        setSubmitted(true);
        setResultTrue(false);
        setResultFalse(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitted(true);
      setResultTrue(false);
      setResultFalse(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      upload_cv: file,
    }));
  };

  return (
    <>
     <Helmet>
        <title>
        Accounting & Financial Consulting Careers at Our Firm make it creative
        </title>
        <meta
          name="description"
          content="Join our team at a top financial consulting firm offering accounting services, tax preparation, bookkeeping, and financial advisory. Grow with us today."
        />
        <meta
          name="keywords"
          content="Financial consulting firm ,Tax preparation , Financial consulting company,Financial advisory firm,  Financial consulting company."
        />
      </Helmet>

      <style>
        {`
          @keyframes background {
            0%, 100% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
          }

          .animate-background {
            animation: background 4s ease infinite;
          }

          .card-gradient {
            background: linear-gradient(to right, #86efac, #3b82f6, #8b5cf6);
            padding: 0.2rem;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            border: 1px;
            transition: background-size 4s ease, box-shadow 0.3s ease, border-color 0.3s ease;
            border-radius: 0.75rem; /* Adjusted for a slightly rounded edge */
            height: 210px;
            width: 300px;
          }

          .card-gradient:hover {
            background-size: 400% 400%;
            box-shadow: 0 8px 16px rgba(0,0,0,0.2);
            animation: background 4s ease infinite;
            border-color: rgba(0, 0, 0, 0.2); /* Light border color on hover */
          }

          .card-content {
            border-radius: 0.75rem; /* Match the border radius of the outer card */
            background-color: white;
            padding: 1rem;
            height: 100%; /* Ensure the content fills the card height */
          }

          .date-time {
            font-size: 0.75rem;
            color: #6b7280;
          }

          .title {
            margin-top: 0.125rem;
            font-size: 1.125rem;
            font-weight: 500;
            color: #111827;
          }

          .tag {
            white-space: nowrap;
            border-radius: 9999px;
            background-color: #e0d7f5;
            color: #6d28d9;
            padding: 0.125rem 0.625rem;
            font-size: 0.75rem;
          }
            
        `}
      </style>

      <section className="sectionm">
        <div className="col p-0 m-0">
        

          <div
            className="d-flex justify-content-center align-items-center banner-image"
            style={{
              background: `url(${cib}) no-repeat center`,
              backgroundSize: "cover",
              height: "430px",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3
                className="text-white text-uppercase"
                style={{ fontWeight: "bold" }}
              >
                Careers
              </h3>
            </div>
          </div>

          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ background: "#f0f5f5" }}
          >
            <div className="p-5 d-flex flex-column justify-content-center align-items-center">
              <p
                className="text-center text-dark careerPageText"
                style={{ fontSize: "16px" }}
              >
                Boston Financial Advisory Group is committed to providing its
                employees with a secure, challenging and rewarding work
                environment. We stand by principles like honesty, integrity,
                attention to detail and exemplary service levels and would
                expect our employees to adhere by the same philosophy.
              </p>
              <p
                className="text-center text-dark careerPageText"
                style={{ fontSize: "16px" }}
              >
                We are growing quickly and we need dedicated employee who can
                contribute to the success of our clients.
              </p>
              <div className="d-flex justify-content-center align-items-center mt-15">
                <div
                  className="line1"
                  style={{
                    minWidth: "5rem",
                    height: "2px",
                    background: "black",
                  }}
                ></div>
                <div>
                  <h3
                    className="text-uppercase pl-2 pr-2"
                    style={{
                      color: "#072f5f",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    Join Our team
                  </h3>
                </div>
                <div
                  className="line2"
                  style={{
                    minWidth: "5rem",
                    height: "2px",
                    background: "black",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-4">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-start ml mb-30">
                <div
                  className="col-lg-5 mr-5 shadow-lg custom-div"
                  style={{
                    overflow: "hidden",
                    borderRadius: "17px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <div className="tab-content" id="tab-content">
                    <div
                      className="tab-pane show active fadeIn mt-4"
                      id="v-pills-job-0"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <h3
                          className="text-center"
                          style={{ color: "#072f5f", fontSize: "22px" }}
                        >
                          Apply Online
                        </h3>
                      </div>
                      <form
                        className="pt-10 pb-30 pl-30 pr-30"
                        onSubmit={handleSubmit}
                      >
                        <div
                          className="row"
                          style={{ marginBottom: "20px", gap: "2rem" }}
                        >
                          <div className="col">
                            <TextField
                              fullWidth
                              label="Full Name"
                              variant="outlined"
                              id="inputName"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                              error={submitted && !formData.name}
                              helperText={
                                submitted && !formData.name
                                  ? "Name is required"
                                  : ""
                              }
                            />
                          </div>
                          <div className="col">
                            <TextField
                              fullWidth
                              label="Email"
                              variant="outlined"
                              type="email"
                              id="inputEmail"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                              error={submitted && !formData.email}
                              helperText={
                                submitted && !formData.email
                                  ? "Email is required"
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ marginBottom: "20px", gap: "2rem" }}
                        >
                          <div className="col">
                            <TextField
                              fullWidth
                              select
                              label="Country Code"
                              defaultValue="+91"
                              variant="outlined"
                              id="inputCountryCode"
                              name="countryCode"
                              value={formData.countryCode}
                              onChange={handleChange}
                              required
                            >
                              {[
                                { code: "+1", name: "US" },
                                { code: "+44", name: "UK" },
                                { code: "+91", name: "India" },
                                { code: "+971", name: "UAE" },
                                { code: "+61", name: "Australia" },
                                { code: "+49", name: "Germany" },
                                { code: "+33", name: "France" },
                                { code: "+81", name: "Japan" },
                                { code: "+86", name: "China" },
                                { code: "+55", name: "Brazil" },
                              ].map((country) => (
                                <MenuItem
                                  key={country.code}
                                  value={country.code}
                                >
                                  {country.code} ({country.name})
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className="col">
                            <TextField
                              fullWidth
                              label="Your Phone"
                              variant="outlined"
                              id="inputPhone"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              required
                              error={submitted && !formData.mobile}
                              helperText={
                                submitted && !formData.mobile
                                  ? "Phone number is required"
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginBottom: "20px" }}>
                          <div className="col">
                            <TextField
                              fullWidth
                              label="Address"
                              variant="outlined"
                              id="inputAddress"
                              name="current_location"
                              value={formData.current_location}
                              onChange={handleChange}
                              required
                              error={submitted && !formData.current_location}
                              helperText={
                                submitted && !formData.current_location
                                  ? "Address is required"
                                  : ""
                              }
                            />
                          </div>
                        </div>

                        <div
                          className="row"
                          style={{ marginBottom: "20px", gap: "1rem" }}
                        >
                          <div className="col">
                            <TextField
                              fullWidth
                              label="Total Work Experience "
                              variant="outlined"
                              id="inputExperience"
                              name="yoexp"
                              value={formData.yoexp}
                              onChange={handleChange}
                              required
                              error={submitted && !formData.yoexp}
                              helperText={
                                submitted && !formData.yoexp
                                  ? "Experience is required"
                                  : ""
                              }
                            />
                          </div>
                          <div className="col">
                            <TextField
                              fullWidth
                              label="Highest Qualification "
                              variant="outlined"
                              id="inputQualification"
                              name="highest_qualification"
                              value={formData.highest_qualification}
                              onChange={handleChange}
                              required
                              error={
                                submitted && !formData.highest_qualification
                              }
                              helperText={
                                submitted && !formData.highest_qualification
                                  ? "Qualification is required"
                                  : ""
                              }
                            />
                          </div>
                          <div className="row">
                            <div className="col">
                              <TextField
                                fullWidth
                                type="file"
                                variant="outlined"
                                id="inputCV"
                                name="cv"
                                onChange={handleFileChange}
                                required
                                error={submitted && !formData.cv}
                                helperText={
                                  submitted && !formData.cv
                                    ? "CV is required"
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{ height: "100px", width: "105%" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center ">
                          <Button
                            className="buttonUnique"
                            variant="contained"
                            color="primary"
                            style={{
                              backgroundColor: "#072f5f",
                              color: "white",
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                        {resultTrue && (
                          <Alert severity="success" className="mt-3">
                            Thank You for reaching out. We would get back to you
                            shortly.
                          </Alert>
                        )}
                        {resultFalse && (
                          <Alert severity="error" className="mt-3">
                            Something went wrong, please try again
                          </Alert>
                        )}
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col">
                  {/* <div id="career-list" className="career-list mt-20 pb-20" style={{ overflowY: 'scroll' }}>
                    {jobList.map((job) => (
                      <div className="card nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" key={job.id}>
                        <div className="card-body d-flex align-items-center">
                          <div className="container">
                            <div className="row">
                              <p className="card-title mr-auto" style={{ color: '#072f5f' }}>
                                {new Date(job.createdAt).toLocaleDateString()}
                              </p>
                            </div>
                            <div className="row">
                              <h4 style={{ color: '#072f5f' }}>{job.title}</h4>
                            </div>
                          </div>
                          <img src="../../../../assets/images/BFAG_logo.png" style={{ width: '20%' }} className="card-img-top" alt="Image" />
                        </div>
                        <div className="m-3">
                          <p className="card-text" style={{ color: '#072f5fa1' }}>
                            <i className="mr-1 fa fa-graduation-cap text-dark fa-1x" aria-hidden="true" style={{ color: 'black' }}></i>
                            {job.qualification[0]}
                          </p>
                          <p className="card-text mt-10" style={{ color: '#072f5fa1' }}>
                            <i className="mr-1 fa fa-briefcase text-dark fa-1x" aria-hidden="true" style={{ color: 'black' }}></i>
                            Work Timing & Location - {job.timingAndLocation}
                          </p>
                          <button className="btn btn-primary float-right nav-link border-0" style={{ background: '#072f5f' }}>
                            View Job
                          </button>
                        </div>
                      </div>
                    ))}
                  </div> */}
                  <article className="card-gradient rounded-xl hover:animate-background mb-3">
                    <div
                      className="card-content d-flex flex-column align-items-center justify-content-center text-center"
                      style={{ gap: "1rem", height: "100%" }}
                    >
                      <img
                        src={vlogger}
                        alt="Vlogger GIF"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <h4 className="">Unlock Your Potential with Us</h4>
                      <Link
                        to=""
                        style={{
                          textDecoration: "none",
                          fontWeight: "300",
                          color: "#000",
                        }}
                      >
                        <span className="">
                          Join us to elevate your career and reach new heights.
                        </span>
                      </Link>
                    </div>
                  </article>

                  <article className="card-gradient rounded-xl hover:animate-background mb-3 mt-4">
                    <div
                      className="card-content d-flex flex-column align-items-center justify-content-center text-center"
                      style={{ gap: "1rem", height: "100%" }}
                    >
                      <img
                        src={growth}
                        alt="Vlogger GIF"
                        className=""
                        style={{ width: "50px", height: "50px" }}
                      />

                      <h4 className="">Shape the Future of Finance</h4>
                      <Link
                        to=""
                        style={{
                          textDecoration: "none",
                          fontWeight: "300",
                          color: "#000",
                        }}
                      >
                        <span className="">
                          Contribute to cutting-edge solutions and
                          industry-leading strategies.
                        </span>
                      </Link>
                    </div>
                  </article>
                </div>
                <div className="row ">
                  <article className="card-gradient rounded-xl hover:animate-background ml-3 mb-3">
                    <div
                      className="card-content d-flex flex-column align-items-center justify-content-center text-center"
                      style={{ gap: "1rem", height: "100%" }}
                    >
                      <img
                        src={analytics}
                        alt="Vlogger GIF"
                        className=""
                        style={{ width: "50px", height: "50px" }}
                      />

                      <h4 className="">Drive Innovation and Excellence</h4>
                      <Link
                        to=""
                        style={{
                          textDecoration: "none",
                          fontWeight: "300",
                          color: "#000",
                        }}
                      >
                        <span className="">
                          Be part of a team that thrives on creativity and high
                          performance.
                        </span>
                      </Link>
                    </div>
                  </article>
                  
                  <article
                    className="card-gradient rounded-xl hover:animate-background ml-3"
                    style={{ marginTop: "-104px " }}
                  >
                    <div
                      className="card-content d-flex flex-column align-items-center justify-content-center text-center"
                      style={{ gap: "1rem", height: "100%" }}
                    >
                      <img
                        src={file}
                        alt="Vlogger GIF"
                        className="mt-4"
                        style={{ width: "50px", height: "47px" }}
                      />

                      <h4 className="">
                        Build a Career with Purpose 
                      </h4>
                      <Link
                        to=""
                        style={{
                          textDecoration: "none",
                          fontWeight: "300",
                          color: "#000",
                        }}
                      >
                        <span className="">
                          Make a difference with work that truly matters and
                          influences the industry.
                        </span>
                      </Link>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
