import React from "react";
import abstractRight from "../../../assets/images/why-bfag/abstract-left.png";
import abstractLeft from "../../../assets/images/why-bfag/abstract-right.png";
import whyBfag from "../../../assets/images/why-bfag/Why-bfag.png";
import designLeft from "../../../assets/images/why-bfag/design-left.png";
import designRight from "../../../assets/images/why-bfag/design-right.png";
import img3 from "../../../assets/images/why-bfag/3.png";
import img1 from "../../../assets/images/why-bfag/1.png";
import img4 from "../../../assets/images/why-bfag/4.png";
import img2 from "../../../assets/images/why-bfag/2.png";
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import Tagline from "../../../Tagline";
import "./whybfg.css";
import { Helmet } from "react-helmet";

const WhyBfag = () => {
  return (
    <>

  <Helmet>
        <title>
        Boston Financial Advisory Group: Expert CPA & Corporate Financial Consultants
        </title>
        <meta
          name="description"
          content="Boston Financial Advisory Group offers comprehensive financial consulting services for businesses. Our team of expert CPAs and corporate financial advisors provides tailored solutions in accounting, tax compliance, and strategic financial planning. Trust our experienced consultants to drive your business's financial success."
        />
        <meta
          name="keywords"
          content="Financial Advisory Group,  Boston financial advisors, Corporate financial advisors, Financial consultants, CPA and financial advisor, Financial consulting company, Financial advisory firm, outsource accounting"
        />
      </Helmet>

      <section className="min-vh-100 mt-70">
        <div className="row d-flex justify-content-center align-items-center pTop m-0 backgroundImgone content-container">
          <img src={abstractRight} alt="shape" className="abstract-right" />
          <img src={abstractLeft} alt="shape" className="abstract-left" />

          <div className="col-lg-6 text-black-50 pr-10 m-4 m-0 why-bfag-image-container">
            <div className="row p-3 image-border">
              <img className="img-fluid" src={whyBfag} alt=""  style={{alignItems:'start'}}/>
            </div>
          </div>

          <div className="col-lg-6 pt-0 why-bfag-text-container" style={{alignItems:'start'}}>
            <h2 className="why-bfag-title" style={{ color: "#072f5f", alignItems:'start' }}>
              Why BFAG
            </h2>
            <p className="text-dark pl-5 pr-4 why-bfag-paragraph">
              Boston Advisory Financial Group is one of the most demanded
              outsourcing partners for accounting and financial consulting
              purposes. This was made possible purely due to our ingenious
              strategies and quality accounting practices. A combination of
              these two aspects allows us to customize results and bring forward
              insight-driven solutions that maximize your efficiency and
              results.
            </p>
            <p className="mb-15 text-dark pl-5 pr-4 why-bfag-paragraph">
              However, these are not the only aspects which make BFAG a
              lucrative choice for our clients. Our solutions are programmed to
              strategically enhance your activities. Implementing our
              technology-oriented services in unison with the solutions enables
              us to take over your financial processes and engineer them to
              ensure accuracy and minimum human intervention. With constant 24×7
              secure online access, we push you forward and enable you to
              outperform your competitors.
            </p>
          </div>
        </div>

        <div className="cards-section">
          <img src={designLeft} alt="shape" className="design-left" />
          <img src={designRight} alt="shape" className="design-right" />

          <div className="row d-flex justify-content-center mt-20 mb-25">
            {/* Card 1 */}
            <div className="col-lg-3 pl-4 card-container">
              <div className="card1 p-2 wow fadeInDown shadow card-custom">
                <div className="d-flex justify-content-center align-items-center pt-1">
                  <img className="res-img" src={img3} alt="Card image cap" />
                </div>
                <div className="card-body-one">
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <span
                      className="card-title text-center"
                      style={{
                        display: "inline-block",
                        borderBottom: "3px solid #072f5f",
                        color: "#072f5f",
                        fontWeight: "bold",
                        paddingBottom: "4px",
                      }}
                    >
                      Competence
                    </span>
                  </div>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      // lineHeight: "normal",
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    Profit from the extensive knowledge and expertise of our
                    team, accumulated over 1000 plus years of providing
                    accounting and advisory services. We deliver personalized
                    insights to address your specific requirements.
                  </li>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      // lineHeight: "normal",
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    Our certified CPAs and CAs demonstrate exceptional
                    proficiency in delivering accounting, tax, regulatory
                    compliance.
                  </li>
                </div>
              </div>
            </div>
            {/* Card 2 */}
            <div className="col-lg-3 card-container">
              <div className="card1 p-2 wow fadeInDown shadow card-custom">
                <div className="d-flex justify-content-center align-items-center pt-1">
                  <img className="res-img" src={img1} alt="Card image cap" />
                </div>
                <div className="card-body-one">
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <span
                      className="card-title text-center"
                      style={{
                        display: "inline-block",
                        borderBottom: "3px solid #072f5f",
                        color: "#072f5f",
                        fontWeight: "bold",
                        paddingBottom: "4px",
                      }}
                    >
                      Extensive Offering
                    </span>
                  </div>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      // lineHeight: "normal",
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    At BFAG, we pride ourselves on providing a comprehensive
                    range of financial services to cater to the diverse
                    multi-jurisdictional needs of our clients. Our extensive
                    offering covers a wide spectrum of financial solutions,
                    ensuring that you can find everything you require under one
                    roof.
                  </li>
                </div>
              </div>
            </div>
            {/* Card 3 */}
            <div className="col-lg-3 card-container">
              <div className="card1 p-2 wow fadeInDown shadow card-custom">
                <div className="d-flex justify-content-center align-items-center pt-1 ">
                  <img className="res-img" src={img4} alt="Card image cap" />
                </div>
                <div className="card-body-one">
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <span
                      className="card-title text-center"
                      style={{
                        display: "inline-block",
                        borderBottom: "3px solid #072f5f",
                        color: "#072f5f",
                        fontWeight: "bold",
                        paddingBottom: "4px",
                      }}
                    >
                      Exceptional Solutions
                    </span>
                  </div>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      // lineHeight: "normal",
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    We ensure the implementation of the finest accounting
                    practices and strict adherence to ISO 9001 and 27001
                    standards.
                  </li>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      // lineHeight: "normal",
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    Stay updated on business performance with our best-in-class
                    accounting services. Gain valuable insights to drive growth
                    and achieve your goals.
                  </li>
                </div>
              </div>
            </div>
            {/* Card 4 */}
            <div className="col-lg-3 pr-4 card-container">
              <div className="card1 p-2 wow fadeInDown shadow card-custom">
                <div className="d-flex justify-content-center align-items-center pt-1">
                  <img className="res-img" src={img2} alt="Card image cap" />
                </div>
                <div className="card-body-one">
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <span
                      className="card-title text-center"
                      style={{
                        display: "inline-block",
                        borderBottom: "3px solid #072f5f",
                        color: "#072f5f",
                        fontWeight: "bold",
                        paddingBottom: "4px",
                      }}
                    >
                      Reliability
                    </span>
                  </div>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      // lineHeight: "normal",
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    Experience the reliability of our accounting services,
                    delivered by a trusted partner. With over 12+ years of
                    excellence, we ensure secure and accurate financial
                    management.{" "}
                  </li>
                  <li
                    className="mb-15 mt-10"
                    style={{
                      textAlign: "justify",
                      fontWeight: "400",
                    }}
                  >
                    Count on our transparent processes, confidentiality, and
                    adherence to ethical standards. We provide peace of mind,
                    knowing your financial matters are in trusted hands.
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tagline />
    </>
  );
};

export default WhyBfag;
