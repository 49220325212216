import React from "react";
import ganesh from "../../../../assets/images/teams/ganesh.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import niteshnew from "../../../../assets/images/teams/Niteshnew.jpg";

const Niteshvaswani = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: "1%" }}>
              <img
                style={{
                  borderRadius: "10px",
                  width: "320px",
                  height: "400px",
                }}
                className="img-fluid"
                src={niteshnew}
                alt="nitesh"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3
              className="text-uppercase text-dark title"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              Nitesh Vaswani
            </h3>
            <div>
              <span
                className="text-uppercase text-dark"
                style={{ fontSize: "16px", fontWeight: 600 }}
              >
                VP – International Operations
              </span>
              <a
                style={{
                  color: "#fff",
                  marginLeft: "10px",
                  backgroundColor: "#0072b1",
                  padding: "4px",
                  borderRadius: "5px",
                }}
                href="https://www.linkedin.com/in/nitesh-vaswani-6bbb5368/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ color: "#fff" }}
                />
              </a>
            </div>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Nitesh, a Certified Public Accountant and Chartered Accountant
              with an MBA in Finance, brings over a decade of expertise in
              Mergers & Acquisitions, accounting across jurisdictions,
              consolidation, reporting, and compliance. His experience spans
              diverse industries, including chemicals, construction, and
              healthcare, giving him a robust understanding of international
              reporting standards.
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              As a tax compliance specialist, Nitesh has consistently delivered
              value to international clients, and his skill in managing
              multicultural teams greatly enhances our company’s global
              capabilities.{" "}
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              His experience spans diverse industries, including chemicals,
              construction, and healthcare, giving him a robust understanding of
              international reporting standards. As a tax compliance specialist,
              Nitesh has consistently delivered value to international clients,
              and his skill in managing multicultural teams greatly enhances our
              company’s global capabilities.{" "}
            </p>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default Niteshvaswani;
