import React, { useState } from "react";
import casestudy from "../../../assets/images/case-studies/case-study-main-banner.png";
import it from "../../../assets/images/case-studies/IT.png";
import law from "../../../assets/images/case-studies/law.png";
import cpa from "../../../assets/images/case-studies/CPA.png";
import vc from "../../../assets/images/case-studies/vc.png";
import { Link } from "react-router-dom";
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import { Helmet } from "react-helmet";

const Casestudies = () => {
  const [hover, setHover] = useState(false);
  return (
    <>
 <Helmet>
        <title>
        Case Studies | Boston Financial Advisory Group
        </title>
        <meta
          name="description"
          content="Explore real-world success stories from Boston Financial Advisory Group. Our case studies showcase how we've helped clients across various industries, with expert financial advisory services and tailored solutions."
        />
        <meta
          name="keywords"
          content="Consulting and advisory services, Regulatory compliance, Financial accounting, Outsourcing accounting services, Accounting consultant."
        />
      </Helmet>

      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
      `}</style>

      <section className="">

        {/* <div
          className="banner-wrapper"
          style={{ position: "relative", height: "400px" }}
        >
          <video
            className="banner-video"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={videoBackground}
            autoPlay
            loop
            muted
          />
          <div
            className="banner-overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="container-fluid trdBannar h-100">
              <div className="row h-100 d-flex align-items-center">
                <div className="col-lg-6">
                  <div className="banner-content text-white">
                    <h1
                      className="title ml-3"
                      style={{
                        animation: "fadeInLeft 0.5s",
                        animationDelay: "0.5s",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      <a style={{ fontSize: "30px", justifyContent: "center" }}>
                        {" "}
                        Case Study
                      </a>
                      <br />
                    </h1>
                    <li
                      className="pt-5 "
                      style={{
                        listStyleType: "none",
                        animation: "fadeInLeft 1.3s",
                        animationDelay: "1.3s",
                      }}
                    >
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

<div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${casestudy}) no-repeat center`,
          backgroundSize: "cover",
          height: "430px",
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3
            className="text-white text-uppercase"
            style={{ fontWeight: "bold" }}
          >
            case study
          </h3>
        </div>
      </div>

        <div
          className="mt-50 container"
         
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={it}
              alt=""
              style={{ marginBottom: "2rem" }}
            />
            <div className="border" style={{ padding: "1rem" }}>
              <div
                style={{
                  background: "#072f5f",
                  display: "inline",
                  width: "fit-content",
                  padding: "0.2rem 1rem 0.2rem 1rem",
                }}
              >
                <small
                  className="text-uppercase text-white"
                  style={{ fontWeight: "600", fontSize: "15px" }}
                >
                  case study
                </small>
              </div>

              <div>
                <div className="mt-15">
                  <span
                    className="text-capitalize"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      // color: "#002249",
                    }}
                  >
                    IT Company Case Study  
                  </span>
                </div>
                <p className="text-dark servicep" style={{textAlign:'justify'}}>
                  MClient is into providing customized & cost effective
                  solutions in the areas of SAP/JD Edwards implementation such
                  as Business Process Reengineering, Expert Consulting,
                  Training, Solution reviews, Safeguarding Services, Post
                  implementation maintenance support. The client is having
                  operations spread over places such as Australia, USA,
                  Malaysia, South America, Africa apart from India.
                  <br />
                  <Link
                    to="/Itcompanycasestudy"
                    style={{
                      color: hover ? "black" : "#072f5f",
                      fontWeight: "bold",
                      // textDecoration: 'none',
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    Read more
                  </Link>
                </p>
                <div className="d-flex justify-content-start align-items-start mt-30">
                  <small
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#072f5f",
                    }}
                  >
                    <i className="fas fa-user"></i> By BFAG
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-50 container"
          
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={law}
              alt=""
              style={{ marginBottom: "2rem" }}
            />
            <div className="border" style={{ padding: "1rem" }}>
              <div
                style={{
                  background: "#072f5f",
                  display: "inline",
                  width: "fit-content",
                  padding: "0.2rem 1rem 0.2rem 1rem",
                }}
              >
                <small
                  className="text-uppercase text-white"
                  style={{ fontWeight: "600", fontSize: "15px" }}
                >
                  case study
                </small>
              </div>
              <div>
                <div className="mt-15">
                  <span
                    className="text-capitalize text-dark"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      color: "#002249",
                    }}
                  >
                    Law Firm Case Study 
                  </span>
                </div>
                <p
                  className="text-dark servicep"
                  style={{ fontWeight: "normal", textAlign:'justify' }}
                >
                  Law Firm specialized in real estate closing support services
                  and advisory services. The team, The team comprises of best of
                  the lawyers each heading a different expert area.
                  <br />
                  <Link
                    to="/lawfirmcasestudy"
                    style={{
                      color: hover ? "black" : "#072f5f",
                      fontWeight: "bold",
                      // textDecoration: 'none',
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    Read more
                  </Link>
                </p>
                <div className="d-flex justify-content-start align-items-start mt-30">
                  <small
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#072f5f",
                    }}
                  >
                    <i className="fas fa-user"></i> By BFAG
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-50 mb-50 container"
         
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={cpa}
              alt=""
              style={{ marginBottom: "2rem" }}
            />
            <div className="border" style={{ padding: "1rem" }}>
              <div
                style={{
                  background: "#072f5f",
                  display: "inline",
                  width: "fit-content",
                  padding: "0.2rem 1rem 0.2rem 1rem",
                }}
              >
                <small
                  className="text-uppercase text-white"
                  style={{ fontWeight: "600", fontSize: "15px" }}
                >
                  case study
                </small>
              </div>
              <div>
                <div className="mt-15">
                  <span
                    className="text-capitalize text-dark"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      color: "#002249",
                    }}
                  >
                    CPA Case Study 
                  </span>
                </div>
                <p className="text-dark servicep" style={{textAlign:'justify'}}>
                  When a Business expands its operations, managing data &
                  documents emerge as one of the major problem. A Business also
                  need to identify the areas that need to be reworked such as
                  cutting the cost in certain field and adding more to another &
                  It is possible to establish the long-term goals in Business
                  when you outsource some of the back-end accounting operations.
                  By outsourcing all your routine accounting functions, you’re
                  able to focus on managing and growing your business.
                  <br />
                  <Link
                    to="/CPAcasestudy"
                    style={{
                      color: hover ? "black" : "#072f5f",
                      fontWeight: "bold",
                      // textDecoration: 'none',
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    Read more
                  </Link>
                </p>

                <div className="d-flex justify-content-start align-items-start mt-30">
                  <small
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#072f5f",
                    }}
                  >
                    <i className="fas fa-user"></i> By BFAG
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-50 mb-50 container"
          
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={vc}
              alt=""
              style={{ marginBottom: "2rem" }}
            />
            <div className="border" style={{ padding: "1rem" }}>
              <div
                style={{
                  background: "#072f5f",
                  display: "inline",
                  width: "fit-content",
                  padding: "0.2rem 1rem 0.2rem 1rem",
                }}
              >
                <small
                  className="text-uppercase text-white"
                  style={{ fontWeight: "600", fontSize: "15px" }}
                >
                  case study
                </small>
              </div>
              <div>
                <div className="mt-15">
                  <span
                    className="text-capitalize"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      // color: "#002249",
                    }}
                  >
                    Vc Firm Case Study 
                  </span>
                </div>
                <p className="text-dark servicep" style={{textAlign:'justify'}}
                >
                  MClient is into providing customized & cost effective
                  solutions in the areas of SAP/JD Edwards implementation such
                  as Business Process Reengineering, Expert Consulting,
                  Training, Solution reviews, Safeguarding Services, Post
                  implementation maintenance support. The client is having
                  operations spread over places such as Australia, USA,
                  Malaysia, South America, Africa apart from India.
                  <br />
                  <Link
                    to="/vcfirmcasestudy"
                    style={{
                      color: hover ? "black" : "#072f5f",
                      fontWeight: "bold",
                      // textDecoration: 'none',
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    Read more
                  </Link>
                </p>
                <div className="d-flex justify-content-start align-items-start mt-30">
                  <small
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "#072f5f",
                    }}
                  >
                    <i className="fas fa-user"></i> By BFAG
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Casestudies;
